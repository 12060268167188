export const API = { //NO TOCAR las 2 lineas siguientes. Se modifican con setcliente
    personalizada:null,
    skin: {name:'Comboi',web:'https://www.comboiapp.es'},

	urlx: "http://localhost/comboi/api3/web",
  url: "https://api3.comboiapp.es",
  urlback: "https://user.comboiapp.es/site/login-token",
	appversion: '3.8.1',
  versioncode: '030801'
}


