import { UtilService } from './util.service';
import { Capacitor } from '@capacitor/core';

import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { BehaviorSubject, Observable } from 'rxjs';
import { SQLite, SQLiteObject } from '@awesome-cordova-plugins/sqlite/ngx';
import { SQLitePorter } from '@awesome-cordova-plugins/sqlite-porter/ngx';
import { chatsSchema} from './dbsch_chats';

const DB_VERSION = 4;
const DBNAME='comboi';

@Injectable({
  providedIn: 'root'
})

export class DbService {
  private db: SQLiteObject;
  public native:boolean;
  private opened:boolean=false;

  chatsList = new BehaviorSubject([]);
  private isDbReady: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private platform: Platform,
    private sqlite: SQLite,
    private utilS:UtilService,
    private sqlPorter: SQLitePorter,
  ) {
  }

  async initializePlugin(): Promise<any> {
    await this.platform.ready().then(() => {
      var platform = Capacitor.getPlatform();
      this.native=platform === 'ios' || platform === 'android';

      return this.init();
    });

  }

  async init(): Promise<void> {
    if (this.native) {
      try {
        //await this.capsql.requestPermissions();
        return await this.opendb();
      } catch (e) {
        this.utilS.showErr(JSON.stringify(e));
       // console.log('SQL  Error al crear DB',e);
      }
    } else {
      return await this.opendb();
    }
  }

  async opendb() :Promise<any>{
    if(!this.native) return Promise.resolve(null);
    //console.log('SQL opendb');

    let b=false;
    //if(b)await this.capsql.deleteDatabase({database:DBNAME});

    let nueva:boolean=true;
    //Comprueba si existe, y la versión
    try{

      await this.sqlite.create({name:DBNAME,location:'default'}).then(db=>{
       // console.log('SQL DB ok');
        this.db=db;
        this.opened=true;
        this.getversion().then(version=>{
          console.log("SQL Versión ",version);
          if(version!=DB_VERSION) {
            console.log('SQL Migrando version '+DB_VERSION); 
            this.sqlPorter.importSqlToDb(this.db, chatsSchema).then(() => {
                console.log('SQL Importada OK');}
              ).catch(e => console.error("SQL Import "+e));
          }
        });

      });
      Promise.resolve(true);
      return;
    } catch (e) {
      console.log("SQL Error",e);
      Promise.resolve(false);

    }
  }
  async getversion():Promise<number>{
    let version=0;
    await this.exec('PRAGMA user_version').then(res=>{
      if(res.rows.length)  {
        version=res.rows.item(0).user_version;
      }
    });
    return Promise.resolve(version);
  }
  //Recrea la BD
  public async reset():Promise<any>{
    let k=await this.exec('delete from chats');
    console.log('SQL chats borrados',k);
    this.exec('delete from mensajes').then(s=>{
      console.log("SQL mensajes borrados",s);
    });
  }

  public async execSet(set:any[],trans:boolean=false):Promise<any>{
    if(!this.native) Promise.resolve({changes:0});
    for(let i=0;i<set.length;i++) {
       await this.exec(set[i].statement,set[i].values);
    };
  }

  public query(sql:string,values:any[]=[]):Promise<any>{
    if(!this.native)
      return Promise.resolve([]);
    else
      return this.db.executeSql(sql,values);
  }


 exec(sql:string,values:any[]=[]):Promise<any>{
  if(!this.native) return Promise.resolve([]);
  //console.log('SQL exec',sql);
  return this.db.executeSql(sql,values);
  /*.then((r)=>{
    console.log('SQL res',r);
  }).catch((err)=>{
    console.log("Error sql "+sql,err);
  })*/
 }


  dbState() {
    return this.isDbReady.asObservable();
  }

}
