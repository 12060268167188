import { User } from './modelos/user';
import { Modulo } from './modelos/modulo';
import { DbService } from './services/db.service';
import { ModalPage } from './pages/modal/modal.page';
import { API } from './api';
import { ModalController } from '@ionic/angular';
import { MenuController } from '@ionic/angular';
import { Component, OnInit, ElementRef } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { AlertController, Platform } from '@ionic/angular';
import { SplashScreen } from '@capacitor/splash-screen';
import { Location } from '@angular/common';
import { CacheService } from 'ionic-cache';

import { UserService } from 'src/app/services/user.service';
import { StorageService } from './services/storage.service';
import { EnvironService } from './services/environ.service';
import { UtilService } from './services/util.service';
import { AuthService } from './services/auth.service';
import { ChatsService } from './services/chats.service';
import { OnesignalService } from './services/onesignal.service';
import { AccesoPage } from './modals/acceso/acceso.page';
import { LOCALE_ID } from '@angular/core';
import { register } from 'swiper/element/bundle';
import { Subscription } from 'rxjs';
import { ApuntesService } from './services/apuntes.service';
import { EventosService } from './services/eventos.service';
import { ClasesService } from './services/clases.service';
import { NoticiasService } from './services/noticias.service';
import { EncuestasService } from './services/encuestas.service';
import { PedidosService } from './services/pedidos.service';
import { DocsService } from './services/docs.service';
import { StatusBar, Style } from '@capacitor/status-bar';
register(); //swiper

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  // public cliente: Cliente;
  web: string;
  userRol: string; 
  api: any = API;

  public selectedIndex = 0;
  public appPages1 = [
    { title: 'Inicio', url: '/home', icon: 'home' },
    { title: 'Mi Perfil', url: '/ajustes', icon: 'person' },
    { title: 'Mis Grupos / Inscripciones', url: '/grupos', icon: 'people' },
  ];
  public appPages2 = [
    { title: 'Contacto', url: '/contacto', icon: 'mail' },
    { title: 'Actualizar Datos', url: '/actualizar', icon: 'reload' },
    { title: 'Privacidad', url: '*privacidad', icon: 'information-circle' },
  ];

  public appInvitado = [
    { title: 'Inicio', url: '/home', icon: 'home' },
    { title: 'Horarios', url: '/grupos', icon: 'time' },
    { title: 'Contacto', url: '/contacto', icon: 'mail' },
    { title: 'Actualizar Datos', url: '/actualizar', icon: 'reload' },
    { title: 'Iniciar Sesión', url: '/login', icon: 'enter' },
  ];
  notifSub: Subscription;

  isDesktop: boolean;

  constructor(
    private platform: Platform,

    public storage: StorageService,
    public element: ElementRef,
    private router: Router,
    private route: ActivatedRoute,
    private alertCtrl: AlertController,
    private menuCtrl: MenuController,
    private location: Location,
    public modalController: ModalController,
    public util: UtilService,
    cache: CacheService,
    public onesignal: OnesignalService,
    public environ: EnvironService,
    public authS: AuthService,
    private dbase: DbService,

    public apuntesS: ApuntesService,
    public eventosS: EventosService,
    public clasesS: ClasesService,
    public chatsS: ChatsService,
    public userS: UserService,
    public noticiasS: NoticiasService,
    public encuestasS: EncuestasService,
    public pedidosS: PedidosService,
    public docsS: DocsService,
    public utilS: UtilService
  
  ) {
    cache.setDefaultTTL(60 * 60);
    // this.initializeApp();
  }

  ngOnInit() {
    SplashScreen.show({
      autoHide: false
    }) ;
    
    this.util.HayInternet();
    this.start();
  }

  async start() {
    
    // this.environ.clear();
    let ok=await this.initializeApp();

    //if(!ok) return; //Ha cambiado la versión
    let lastcliente = await this.storage.getString('lastcliente');
    if(lastcliente){
      /* No sale splash 
      let res=await this.authS.getwait('clientes/99','cliente='+lastcliente+'&cliversion=1');
      this.environ.cliversion=res['cliversion'];*/

      this.authS.getCliversion(lastcliente).subscribe((res) => {
        console.log('Versión cliente API ',res);
        this.environ.cliversion=res['cliversion'];
        if(this.environ.cliversion!=this.environ.cliente?.cliversion) {
          console.log('Versión cliente actual es distinta: ',this.environ.cliente?.cliversion);
          this.environ.reload();
        }
      });
    } else
      this.environ.cliversion='0';
    
    //c=cliente por parámetro web or c=- para ir a cliente
    let cliente=this.platform.getQueryParam('c');
    console.log("cliente param",cliente);
    if(cliente){
      if(cliente!='-'){
        this.storage.setString('lastcliente',cliente);
        await this.environ.getCliente(cliente);
        this.router.navigate(['login']);
      } else {
        await this.environ.clearCliente();
        this.router.navigate(['cliente']);
      } 

    } else{
      await this.environ.loadCliente(cliente!==null);

      //if(!ok || (this.environ.cliversion!=this.environ.cliente.cliversion)) {
      if(!ok)  {
        console.log('Versión app actual es distinta: ',this.environ.cliente.cliversion);
        await this.environ.reload();
      }
    }
    //SplashScreen.hide();  
    setTimeout(() => {
      SplashScreen.hide();  
    }, 900);

    if (!this.environ.cliente ) { //|| (this.api.personalizada && this.api.personalizada!=this.environ.cliente.cliente )
      //   this.environ.miUser.subscribe((user) => (this.userRol = user));
      //    this.environ.miCliente.subscribe((clix) => (this.cliente = clix));
      this.router.navigate(['cliente']);
    } else {
      
      await this.environ.loadUser();
      if (!this.environ.has('clases')) this.appPages1[2].title = 'Mis Grupos';
      this.appPages1[0].url='/'+this.environ.homepage;
      
      if (!this.environ.user) {
        //this.environ.changeTipoUser(this.userRol);*/
        const start = this.environ.cliente.config?.start;
        if ((start === 'I') || (start === ''))
          this.router.navigate(['login']);
        else if (start == 'L')
          this.router.navigate(['login']);
        else {
          //Entra como invitado
          this.authS.loginGuest(this.environ.cliente.cliente).subscribe(
            (res) => {
              this.environ.setUsuario(res['data']);
              this.onesignal.init();
              this.userS.SetPlayerId('#').subscribe(r=>{
                console.log('SetPlayer ok');
              });
            },
            (err) => {
              //   this.presentModal(err);
            }
          );
        }
      } else {
        this.onesignal.init();
        await this.suscribenotif();
        
        this.router.navigate(this.environ.homepage);
      }
    }
  }



  async initializeApp() {
    
    await this.platform.ready().then(() => {
      console.log('platform ready', this.platform);
      this.environ.initDevice();
      this.environ.screen = {
        width: this.platform.width(),
        height: this.platform.height(),
      };
      
      this.dbase.initializePlugin().then((ret) => {
        console.log('SQL init ', ret);
      });  
    });

    if (this.environ.isapp()) {
      
      // Display content under transparent status bar (Android only)
      /* 
      ion-toolbar{  padding-top: 18px !important;}
      Falta resolver los toast, que salen pegados al top
      y el cuadro de texto del chat que no sube al escribir
      */
      //StatusBar.setOverlaysWebView({ overlay: true });


      //StatusBar.setStyle({ style: Style.Light });
      //StatusBar.hide();

      console.log('isApp!');
      
      this.updateSideMenu();

      this.platform.backButton.subscribeWithPriority(5, () => {
        //console.log(this.location.path());
        if(this.location.path() == '/login')
          navigator['app'].exitApp();
        else if ((this.location.path() != '/home') && (this.location.path() != '/homeicon'))
          this.location.back();
      });
      this.platform.resume.subscribe(() => {
        if (this.environ.user) this.chatsS.GetPendientes();
      });

      //NO VA await this.firebaseAuthenticationService.initialize();
      //Badge
      //this.badge.set(10);
      //this.badge.increase(1);
      //this.badge.clear();
    }
    let version=await this.storage.getString('lastversion');
    console.log('Versión anterior '+version+' actual '+API.appversion);
    
    if(version!=API.appversion){
      this.storage.setString('lastversion',API.appversion);
      this.router.navigate(this.environ.getRouteini());
      return false;
    }
    console.log('end init');
    return true;

  }


  async acceso() {
    const modal = await this.modalController.create({
      component: AccesoPage,
      cssClass: 'acceso-modal-css',
      componentProps: {},
      presentingElement: await this.modalController.getTop(),
    });
    modal.present();
  }

  closeModal() {
    //de acceso
    this.modalController.dismiss(null, 'cancel');
  }

  async salir() {
    //logout
    const alert = await this.alertCtrl.create({
      cssClass: 'my-custom-class',
      header: '',
      message: '¿Deseas Cerrar la sesión?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
        },
        {
          text: 'Sí',
          handler: () => {
            this.userS.logout();
            this.menuCtrl.close();
            this.router.navigate(this.environ.getRouteini());
          },
        },
      ],
    });
    await alert.present();
  }

  menuAccionesUser(i: number) {
    let p = this.appPages2[i];
    if (p.url == '*privacidad') this.privacidad();
    else this.router.navigate([p.url]);
  }
  menuModulos(m: Modulo) {
    if (m?.url) window.open(m.url, '_system', 'location=yes');
    else this.router.navigate([m.id]);
  }
  menuAccionesInvitado(i) {
    if (i < 5) this.selectedIndex = i;
    else this.router.navigate(['login']);
  }

  gobackend() {
    var url= API.urlback + '?cliente='+this.environ.cliente.cliente+'&token=' + this.environ.user.token+'&redirect=/';
    console.log(url);
    window.open(url, '_system',  'location=yes' );
  }
  soporte(){
    this.router.navigate(['soporte']);
  }
 notif(){
    this.router.navigate(['notif']);
  }


  //SOBRA?
  updateSideMenu() {
    const path = window.location.pathname.split('folder/')[1];
    if (path !== undefined) {
      this.selectedIndex = this.appPages1.findIndex(
        (page) => page.title.toLowerCase() === path.toLowerCase()
      );
    }
  }
  webcomboi() {
    window.open(this.api.skin.web, '_system', 'location=yes');
  }

  /*privacidad() {
    this.web = this.environ.cliente.config.backurl +'/site/texto?codigo=privacidad&clid=' + this.environ.cliente.id;
    console.log(this.web);
    window.open(this.web, '_system', 'location=no');
    return false;
  }*/

  privacidad() {
    //TODO hacerlo en local modal
    this.userS.GetTexto('privacidad').subscribe(
      (res) => {
        this.presentModal(res);
      },
      (err) => {}
    );
  }

  async presentModal(texto: string) {
    const modal = await this.modalController.create({
      component: ModalPage,
      cssClass: 'condiciones-modal-css',
      componentProps: {
        Condiciones: texto,
      },
    });
    return await modal.present();
  }

//----------------------------- GESTIÓN Notificaciones
  async suscribenotif(){
    if (!this.notifSub){
      console.log('Notif en app.component');

      this.notifSub = this.onesignal.notif$.subscribe((r) =>{
        console.log('Notif en app.component', r);
        this.onnotif(r);
      });
    }
  }
    //Notificación recibida
    async onnotif(r: any) {
      if (!r) return;
  
      var redirect: any = this.environ.homepage;
      switch (r.tipo) {
        case 'cliente':
          case 'reload':
          await this.environ.reload();
          //this.load(true);
          
          break;
        case 'logout':
          this.userS.logout();
          redirect = '';
          this.router.navigate(this.environ.getRouteini());
          break;
        case 'apuntes':
          this.apuntesS.init();
          this.userS.reload();
          redirect = ['apuntes'];
          break;
        case 'mensajes':
          if (r.event == 'O')
            //abierta notif desde afuera
            redirect = ['/chat',r?.chats_id];
          else 
            redirect = false;
          break;
        case 'docs':
          this.docsS.init();
          redirect = ['docs'];
          if (r?.temas_id) { redirect = ['doc', r.temas_id];}
          break;
  
        case 'clases':
          this.clasesS.init();
          redirect = ['clases'];
          break;
        case 'eventos':
          if (r?.id) {
            redirect = ['evento', r.id];
          } else {
            redirect = ['eventos'];
            this.eventosS.init();
          }
          break;
        case 'encuestas':
          redirect = ['encuestas'];
          if (r?.id) {redirect = ['encuesta', r.id];} 
          this.encuestasS.init();
          break;
        case 'pedidos':
          this.pedidosS.init();
          break;
        case 'noticias':
          redirect = ['noticias'];
          if (r?.id) {redirect = ['noticia', r.id];} 
          break;
      }
      if (redirect)
        setTimeout(() => {
          //No refresca bien fondo y tabs
          this.utilS.gotoRefresh(redirect);
          //this.router.navigate(this.environ.homepage);
        }, 500);
    }
  
}
