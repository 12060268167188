import { Directive, ElementRef, Renderer2 } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Directive({
  selector: '[appShowHideTabs]'
})
export class AppShowHideTabsDirective {

  private showTabBarPages: string[] = ['home', 'noticias','clases','eventos','apuntes','docs','pedidos','chats','notif','gastos','encuestas'];
  private hideTabBarPages: string[] = ['noticia', 'clase', 'evento', 'pedido','encuesta'];
  public routeParamPages: string[] = [];

  constructor(
    private router: Router,
    private elemRef: ElementRef,
    private renderer: Renderer2
  ) {
    this.router.events
      .pipe(filter((e) => e instanceof NavigationEnd))
      .subscribe((e: any) => {
        this.showHideTabs(e);
      });
  }

  private showHideTabs(e: NavigationEnd) {
    const urlArray = e.url.split('/');
   // console.log("to ",urlArray);
    var shouldHide=this.showTabBarPages.indexOf(urlArray[1]) == -1;  
    // comprueba cuando es clases/clase, eventos/evento, pedidos/pedido, encuestas/encuesta
    if(!shouldHide && urlArray.length>2 && this.hideTabBarPages.indexOf(urlArray[2]) > -1)
      shouldHide=true;

    /*const pageUrlParent = urlArray[urlArray.length - 2];
    const pageUrl = urlArray[urlArray.length - 1];
    const page = pageUrl.split('?')[0];
    console.log("di",urlArray,pageUrlParent, pageUrl,page);
    const hideParamPage = this.routeParamPages.indexOf(pageUrlParent) > -1;
    const shouldHide = this.hideTabBarPages.indexOf(page) > -1 || hideParamPage;*/
	  this.renderer.setStyle(this.elemRef.nativeElement, 'display', shouldHide? 'none':'flex');
  }

}
