//OK hasta ahora onesignal-cordova-plugin@3.3.2
import { BehaviorSubject, Observable } from 'rxjs';
import OneSignal, { OneSignalPlugin } from 'onesignal-cordova-plugin';

//import OneSignal from 'onesignal-cordova-plugin';
import { CacheService } from 'ionic-cache';
import { Injectable } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { UserService } from '../services/user.service';
import { UtilService } from '../services/util.service';
import { EnvironService } from '../services/environ.service';
import { StorageService } from '../services/storage.service';
import { AuthService } from '../services/auth.service';
import { Notif } from '../modelos/notif';
import OSNotification from 'onesignal-cordova-plugin/dist/OSNotification';
import { DbService } from './db.service';
@Injectable({
  providedIn: 'root',
})
export class OnesignalService {
  public auth: AuthService;
  public token: string;
  //public oneSignal: any;

  private mensajeSource = new BehaviorSubject<any>(null);
  public mensaje$ = this.mensajeSource.asObservable();
  public onMensaje = EventEmitter;

  private notifSource = new BehaviorSubject<any>(null);
  public notif$ = this.notifSource.asObservable();
  public onNotif = EventEmitter;
  public acepta=''; //''=No definido S o N
  public initialized=false;

  constructor(
    public storage: StorageService,
    public userS: UserService,
    public utilS: UtilService,
    public environ: EnvironService,
    protected cache: CacheService,
    public db: DbService
  ) { }

  public init() {
    if (!window['cordova']) return;
    if (this.initialized) return;
    console.log('OSignal init', this.environ.cliente.onesignal_app);
    try {
      //this.oneSignal.setLogLevel({ logLevel: 6, visualLevel: 0 });
      OneSignal.setAppId(this.environ.cliente.onesignal_app);

      OneSignal.addSubscriptionObserver((data) => {
        console.log('OSIGNAL subscrip', data);
      })

      if (this.environ.userRol == 'I')
        OneSignal.sendTags({
          cliente: this.environ.cliente.cliente,
          invitado: '1'
        });
      else
        OneSignal.sendTags({
          cliente: this.environ.cliente.cliente
        });
      //id de Usuario
      OneSignal.setExternalUserId(this.environ.user.id.toString(), (data) => {
        console.log('OSIGNAL external', data);
        //Permiso notif
        var p=this;
        OneSignal.promptForPushNotificationsWithUserResponse(function (accepted) {
          console.log("OSIGNAL: Acepta notificaciones " + accepted);
          p.acepta=accepted?'S' : 'N';
          p.utilS.loading=false;
        }); 
      });
     

      var p: OnesignalService = this;

      //Abiertas
      OneSignal.setNotificationOpenedHandler(function (xdata) {
        const actionId:string = xdata.action.actionId;
        const type:String = xdata.action.type.toString(); // "ActionTaken" | "Opened"
        var data = <Notif>xdata.notification?.additionalData;
        console.log('OSIGNAL Open',actionId,type,data,xdata.notification);

        if (data !== undefined) {
          if (data.tipo == 'temas') data.tipo = 'docs'; //TODO Cambiar en backend
          data['event'] = 'O';
          p.createlog(xdata.notification.title,xdata.notification.body,data?.tipo,data?.id);
          p.notifSource.next(data);
        } else {
          p.createlog(xdata.notification.title,xdata.notification.body,null,null);
          p.utilS.showAlert(xdata.notification.body, xdata.notification.title);
        }
      });


      //Recibidas
      OneSignal.setNotificationWillShowInForegroundHandler((xdata) => {
        var alert = true;
        const notif:OSNotification=xdata.getNotification();
        var data = <Notif>notif.additionalData;
        console.log('OSIGNAL recibida',xdata,data);
        if (data !== undefined) {
          if (data.tipo == 'temas') data.tipo = 'docs'; //TODO Cambiar en backend
          p.createlog(xdata.getNotification().title,xdata.getNotification().body,data?.tipo,data?.id);
          //-----------------------Elimina cache de datos en servicios
          switch (data.tipo) {
            case 'soporte':
              break;
            case 'mensajes':
              p.mensajeSource.next(data);
              alert = false;
              break;
            case 'eventos':
            case 'encuestas':
              //data.id = null; //Me lleva al detalle, porque hay que pasar asid
              break;
            case 'cliente': 
            case 'reload':
            case 'logout':
              alert = false;
              break;
          }
          data['event'] = 'R';
          p.notifSource.next(data);
          //No se muestra
          xdata.complete(null);
        }
        
        if (alert && xdata) {
           //Mensaje manual
           this.utilS.showAlert(xdata.getNotification().body, xdata.getNotification().title);
         }
      })
      this.initialized=true;
    } catch (err) {
      console.log('ERROR OS', err);
    }


  }
  public async createlog(title,body,tipo,oid){
    if(tipo=='mensajes') return;
    let sql="insert or ignore into notif (fecha,tipo,oid,title,body) values (?,?,?,?,?)";
    const fecha=new Date().toLocaleString();
    await this.db.exec(sql,[fecha,tipo, oid, title, body]).then(r=>{
      console.log('SQL Log notif',tipo,oid,title,body);
    });
  }

}
