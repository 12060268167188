// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-label {
  color: blue;
}

ion-card {
  background-color: white;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/apunte/apunte.page.scss"],"names":[],"mappings":"AAAA;EAAU,WAAA;AAEV;;AADA;EAAS,uBAAA;AAKT","sourcesContent":["ion-label{color:blue}\r\nion-card{background-color: white;}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
