import { UtilService } from './util.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { CacheService } from 'ionic-cache';

import { catchError, tap, map } from 'rxjs/operators';
import { StorageService } from './storage.service';
import { API } from '../api';
import { Device } from '@capacitor/device';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends BaseService {
  gtoken: string = null;
  params: {};

  constructor(
    private router: Router,
    private storage: StorageService,
    protected http: HttpClient,
    protected cache: CacheService,
  	public utilS: UtilService,
  ) {
    super(cache, http,utilS);
    this.cachekey = 'auth';

  }

  getClienteApi(cliente: string): Observable<any> {
    /*const info = Device.getInfo();
    console.log("getcliente ",info);*/
	  const paramskin = '&skin=' + (API.personalizada?API.personalizada:'0');
    return this.getdirect('clientes/99', 'cliente=' + cliente + paramskin+ '&expand=actividades');
  }
  getCliversion(cliente: string): Observable<any> {
    return this.getdirect('clientes/99', 'cliente=' + cliente + '&cliversion=1');
  }

  /* coge uuid de deviceInformation . Es el id único del dispositivo */
  loginGuest(cliente:string): Observable<any> {
    const info = Device.getInfo();

    if (info['uuid'])
      this.params = {
        uuid: info['uuid'],
        cliente: cliente,
        platform: info['platform'],
        soversion: info['osVersion'],
      };
    else
      this.params = {
        uuid: '#web',
        cliente: cliente,
        platform: 'web',
        soversion: '9.99.99',
      };


    return this.post('user/authguest', this.params);
  }

  register(usuario): Observable<any> {
    return this.post('user/register', usuario);/*.pipe(
      map((res: Response) => {
        console.log('res register', res);
      }, catchError(this.handleError))
    );*/
  }
}
