// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.my-custom-modal-css .modal-wrapper {
  height: 20%;
  top: 80%;
  position: absolute;
  display: block;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/modal/modal.page.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,QAAA;EACA,kBAAA;EACA,cAAA;AACJ","sourcesContent":[".my-custom-modal-css .modal-wrapper {\r\n    height: 20%;\r\n    top: 80%;\r\n    position: absolute; \r\n    display: block;  \r\n }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
