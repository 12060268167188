import { UtilService } from './util.service';
import { OnesignalService } from 'src/app/services/onesignal.service';
import { Injectable } from '@angular/core';
import {
  Observable,
  of,
  throwError,
  BehaviorSubject,
  Subscription,
} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { CacheService } from 'ionic-cache';
import { Evento } from '../modelos/evento';

@Injectable({
  providedIn: 'root',
})
export class EventosService extends BaseService {
  conn: boolean;

  eventos: Evento[];

  public dataSource = new BehaviorSubject<Evento[]>(null);
  public data$ = this.dataSource.asObservable();

  constructor(
    protected http: HttpClient,
    protected cache: CacheService,
    public utilS: UtilService,
    protected onesignal: OnesignalService
  ) {
    super(cache, http, utilS);
    this.cachekey = 'eventos';
  }

  GetAll(ttl): Observable<any> {
    return this.get('eventos', '', ttl);
  }

  async loadAll(force: boolean = false) {
    console.log('load eventos ');
    if (force || !this.loaded) {
      let res = await this.GetAll(force ? 0 : -1).toPromise();
      this.eventos = res;
      //Para que la vista los coja
      this.dataSource.next(this.eventos);

      //if(!this.loaded)  this.listenOsignal();
      this.loaded = true;
    }
  }
  ListaPasada(id,pasada) {
    return this.put('eventos/'+id, {'listapasada':pasada} );
  }

  init(): Promise<any> {
    this.eventos = [];
    this.loaded = false;
    return this.clear();
  }

  /*  public listenOsignal(){
	if(this.osignalX) return;
	console.log('service eventos listen OS');

	this.osignalX=this.onesignal.notif$.subscribe((res)=>{
		if(res && res.tipo=='eventos') {
			console.log('Notif en servicio ',res?.id);

			this.clear().then(r=>{
				this.eventos=[];
				this.loaded=false;
				//this.loadAll(true);//TODO Optimizar para cargar solamente res.id
			});
		}
	});
  }  */

  GetEvento(id: number, asid: number, expand: string = ''): Observable<any> {
    return this.getdirect(
      'eventos/' + id,
      'asid=' + asid + '&expand=' + expand
    );
  }

  GetAsistentes(id: number): Observable<any> {
    return this.get('eventos/' + id, 'expand=asistentes');
  }

  Update(id: number, datos): Observable<any> {
    this.Setlocal(id, datos.confirmado);
    console.log('actualizo asis',id,datos);
    return this.put('asistentes/' + id, datos);
  }
  Setlocal(asid: number, conf: string) {
    for (let i = 0; i < this.eventos.length; i++) {
      if (this.eventos[i].asisto.asid == asid)
        this.eventos[i].asisto.confirmado = conf;
    }
  }
}
