import { OnesignalService } from './onesignal.service';
import { Injectable } from '@angular/core';
import { Observable, of, throwError, BehaviorSubject } from 'rxjs';
import { UtilService } from './util.service';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { CacheService } from 'ionic-cache';
import { ClasesLista } from '../modelos/clases_lista';

@Injectable({
  providedIn: 'root',
})
export class ClasesService extends BaseService {
  public clases: ClasesLista;
  
  //Filtros
  public fechas: { min: string, max: string, total: number, lista: any[] };
  public grupos: any[] = [];

  public dataSource = new BehaviorSubject<ClasesLista>(null);
  public data$ = this.dataSource.asObservable();

  constructor(
    protected http: HttpClient,
    protected cache: CacheService,
    public utilS: UtilService,
    protected onesignal: OnesignalService
  ) {
    super(cache, http, utilS);
    this.cachekey = 'clases';
  }

  GetAll(params: string = '', ttl: number = -1): Observable<any> {
    return this.get('clases', 'expand=permisos,confirmados', ttl); //Se hace siempre con delayed
  }

  init(): Promise<any> {
    this.clases = null;
    this.loaded = false;
    return this.clear();
  }

  loadAll(force: boolean = false) {
    if (force || !this.loaded)
      this.GetAll('expand=confirmados', force ? 0 : -1).subscribe(
        (res) => {
          this.clases = res;
          //console.log('Clases', this.clases);
          this.getfechas();
          this.dataSource.next(this.clases);
          //          this.environ.changeClase(this.clases);
          this.loaded = true;
        },
        (err) => {
          //this.util.dismissLoading();
        }
      );
  }
  getfechas() {
    this.fechas = { min: '', max: '', total: 0, lista: [] }
    this.grupos=[];
    Object.entries(this.clases).forEach(([tipo, lista]) => {
      lista.forEach((clase) => {
        this.fechas.total++;
        if (clase.fecha < this.fechas.min || this.fechas.min == '') this.fechas.min = clase.fecha;
        if (clase.fecha > this.fechas.max || this.fechas.max == '') this.fechas.max = clase.fecha;
        const fechaExists = this.fechas.lista.find(fecha => fecha.date === clase.fecha);
        if (fechaExists && clase.confirmado == 'S') {
          fechaExists.backgroundColor = this.utilS.confcolorsCSS[clase.confirmado];
        }
        // Si la fecha no existe, agregarla a this.fechas.lista
        else if (!fechaExists)
          this.fechas.lista.push({
            date: clase.fecha,
            textColor: 'white',
            backgroundColor: this.utilS.confcolorsCSS[clase.confirmado]
          });
        if(!this.grupos.find(grupo =>  grupo === clase.grupo))
          this.grupos.push(clase.grupo);

      });
    });
  }
  public listenOsignal() {
    if (this.osignalX) return;
    console.log('service listen OS');

    this.osignalX = this.onesignal.notif$.subscribe((res) => {
      if (res && res.tipo == 'clases') {
        console.log('Notif en servicio ', res.tipo);

        this.clear().then((r) => {
          this.clases = null;
          this.loadAll(true); //TODO Optimizar para cargar solamente res.id
        });
      }
    });
  }

  GetClase(id: string, cuid: string, getalum = false): Observable<any> {
    //id es la id de clase_usuarios
    return this.getdirect(
      'clases/' + id,
      'cuid=' +
      cuid +
      '&expand=permisos,texto,completa,numroles,chatid,porparejas,formulario,confirmados' +
      (getalum ? ',alumnos' : '')
    );
  }

  Update(id, datos): Observable<any> {
    this.clear();
    return this.put('claseusuarios/' + id, datos);
  }

  ListaPasada(id, pasada) {
    return this.put('clases/' + id, { pasada: pasada });
  }

  GetTarifas() {
    return this.get('tarifas', '', 0);
  }

  GetBonoById(id) {
    return this.get('bonos/' + id, '?expand=clases', 0);
  }

  Reservar(claseId: number, usuarioId: number, bonoId: number) {
    return this.post('claseusuarios', {
      bonos_id: bonoId,
      clases_id: claseId,
      usuarios_id: usuarioId,
    });
  }
}
