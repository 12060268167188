import { EnvironService } from './environ.service';
import { Usaldos } from 'src/app/modelos/usaldos';
import { UtilService } from './util.service';
import { Injectable } from '@angular/core';
import { Observable, of, throwError, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { CacheService } from 'ionic-cache';
import { ApunteG } from '../modelos/apunteG';

@Injectable({
  providedIn: 'root',
})
export class ApuntesService extends BaseService {
  usaldos:Usaldos[];
  unames:string[]; //Nombres de los usuarios
  public saldo: number; //Total

  public dataSource = new BehaviorSubject<Usaldos[]>(null);
  public data$ = this.dataSource.asObservable();

  constructor(
    protected http: HttpClient,
    protected cache: CacheService,
    public utilS: UtilService,
    public environ: EnvironService
  ) {
    super(cache, http, utilS);
    this.cachekey = 'apuntes';
  }

  async init(): Promise<any> {
    //this.apuntes=[];
    this.loaded = false;
    await this.clear();
  }

  async loadAll(force: boolean = false) {
    console.log('load apuntes', force);
    this.unames=[];
    if (force || !this.loaded)
      this.GetAll().subscribe((res: Usaldos[]) => {
        this.usaldos=res;
        this.saldo = 0;
        res.forEach((us) => {
          this.unames.push(us?.u ? us.u.nom :'Tú')
          us.saldos.forEach((s) => {
            this.saldo += parseInt(s.importe);
          });
        });
        console.log("apuntes",this.saldo,res);
        this.environ.user.saldo = this.saldo;
        //No se utiliza en apuntes this.dataSource.next(res);
      });
  }
  GetAll(): Observable<any> {
    return this.get('apuntes');
  }

  GetUser(id:string){
    return this.getdirect('usuarios/'+id,'expand=saldo,apuntes');
  }
  
  Create(data: ApunteG) {
    return this.post('apuntes', data);
  }
}
