import { API } from './../api';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { ToastController } from '@ionic/angular';
import { EnvironService } from '../services/environ.service';
//import { LoadingController } from '@ionic/angular';
import { UtilService } from '../services/util.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  //token: string;
  isLoading: boolean = false;
  //params: any = {};
  //cli: string;
  api:any = API;
  constructor(
    //public loadingCtrl: LoadingController,
    public toastController: ToastController,
    public environ: EnvironService,
    public UtilService: UtilService,
    public router:Router

  ) {
    /*const info = this.environ.device;
    this.params = info;
    if(this.environ.cliente)
	  	this.params['cliente']=this.environ.cliente.cliente;*/
  }

  intercept( request: HttpRequest<any>, next: HttpHandler ): Observable<HttpEvent<any>> {
	var aplic=!request.url.includes('clientes') && !request.url.includes('register') && !request.url.includes('lacuerda'); //TODO
	var token:string;
    if (aplic && this.environ.user && (token=this.environ.user.token)) {
      request = request.clone({
        headers: request.headers.set(
          'Authorization',
          'Bearer ' + token
        ),
        setHeaders: {
          'content-type': 'application/json',
          Bearer: token,
          appVersion: this.api.appversion,
          revision: this.environ.revision ? 'R':''

        },
      });
    } 

	if (environment.production && !this.UtilService.conectado) {
		this.UtilService.toast('No hay acceso a Internet!','danger');
		return throwError('No hay acceso a Internet!');
	}

    // Presentamos el Loading al inicio de la llamada
    //this.presentLoading();
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        /*if (event instanceof HttpResponse) {
          // Cerramos el loading en el fin de la llamada
          this.dismissLoading();
          console.log('event--->>>', event);
        }*/
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
		  console.log('http error',error);
      if(error['status']==401 ) {
        console.log('http error no autorizado');
        this.UtilService.toast('Ups! Ha habido un problema de conexión!','danger');
        this.router.navigate(['login']);
      }
			if(error['status']!=404 && error['status']!=422) {
				      console.log('http error',error);
            	this.UtilService.toast('Ups! Ha habido un problema de conexión!','danger');
			}
			return throwError(error);
        }
      ));
  }

}
