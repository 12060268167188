import { FilterclasePipe } from './filterclase/filterclase.pipe';
import { NgModule } from '@angular/core';
import { SafePipe } from './safe.pipe';

@NgModule({
declarations: [SafePipe,FilterclasePipe],
imports: [],
exports: [
	SafePipe,
	FilterclasePipe],
})

export class PipesModule {}
