import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [

  /*{
    path: 'folder/:id',
    loadChildren: () => import('./submenu/folder.module').then( m => m.FolderPageModule)
  },*/
  /*{
    path: 'tabs',
    loadChildren: './tabs/tabs.module#TabsPageModule'
  },*/
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'noticias',
    loadChildren: () => import('./pages/noticias/noticias.module').then( m => m.NoticiasPageModule)
  },
  {
    path: 'eventos',
    loadChildren: () => import('./pages/eventos/eventos.module').then( m => m.EventosPageModule)
  },
  {
    path: 'eventos/evento/:id/:asid',
    loadChildren: () => import('./pages/evento/evento.module').then( m => m.EventoPageModule)
  },

  {
    path: 'evento/:id/:asid',
    loadChildren: () => import('./pages/evento/evento.module').then( m => m.EventoPageModule)
  },
  {
    path: 'evento/:id', /* busca asid en eventosS.eventos */
    loadChildren: () => import('./pages/evento/evento.module').then( m => m.EventoPageModule)
  },

  {
    path: 'cliente',
    loadChildren: () => import('./pages/cliente/cliente.module').then( m => m.ClientePageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'invitados',
    loadChildren: () => import('./pages/invitados/invitados.module').then( m => m.InvitadosPageModule)
  },

  {
    path: 'condiciones',
    loadChildren: () => import('./pages/condiciones/condiciones.module').then( m => m.CondicionesPageModule)
  },
  {
    path: 'contacto',
    loadChildren: () => import('./pages/contacto/contacto.module').then( m => m.ContactoPageModule)
  },
  {
    path: 'clases/clase/:id/:cuid',
    loadChildren: () => import('./pages/clase/clase.module').then( m => m.ClasePageModule)
  },

  {
    path: 'clase/:id/:cuid',
    loadChildren: () => import('./pages/clase/clase.module').then( m => m.ClasePageModule)
  },
  {
    path: 'clases',
    loadChildren: () => import('./pages/clases/clases.module').then( m => m.ClasesPageModule)
  },

  {
    path: 'bonos/:id',
    loadChildren: () => import('./pages/bonos/bonos.module').then( m => m.BonosPageModule)
  },
  {
    path: 'clasebono/:bonoId/:claseId/:usuarioId',
    loadChildren: () => import('./pages/clasebono/clasebono.module').then( m => m.ClasebonoPageModule)
  },
  {
    path: 'comprabono',
    loadChildren: () => import('./pages/comprabono/comprabono.module').then( m => m.ComprabonoPageModule)
  },
  {
    path: 'avisolegal',
    loadChildren: () => import('./pages/avisolegal/avisolegal.module').then( m => m.AvisolegalPageModule)
  },
  {
    path: 'noticias/noticia/:id',
    loadChildren: () => import('./pages/noticia/noticia.module').then( m => m.NoticiaPageModule)
  },

  {
    path: 'noticia/:id',
    loadChildren: () => import('./pages/noticia/noticia.module').then( m => m.NoticiaPageModule)
  },
  {
    path: 'apuntes',
    loadChildren: () => import('./pages/apuntes/apuntes.module').then( m => m.ApuntesPageModule)
  },
  {
    path: 'encuesta/:id',
    loadChildren: () => import('./pages/encuesta/encuesta.module').then( m => m.EncuestaPageModule)
  },
  {
    path: 'grupos',
    loadChildren: () => import('./pages/grupos/grupos.module').then( m => m.GruposPageModule)
  },
  {
    path: 'ajustes',
    loadChildren: () => import('./pages/ajustes/ajustes.module').then( m => m.AjustesPageModule)
  },
  {
    path: 'grupo/:id',
    loadChildren: () => import('./pages/grupo/grupo.module').then( m => m.GrupoPageModule)
  },
  {
    path: 'gastos',
    loadChildren: () => import('./pages/gastos/gastos.module').then( m => m.GastosPageModule)
  },
  {
    path: 'gasto',
    loadChildren: () => import('./pages/gasto/gasto.module').then( m => m.GastoPageModule)
  },
  {
    path: 'apunte',
    loadChildren: () => import('./pages/apunte/apunte.module').then( m => m.ApuntePageModule)
  },


  {
    path: 'user',
    loadChildren: () => import('./pages/user/user.module').then( m => m.UserPageModule)
  },
  {
    path: 'actualizar',
    loadChildren: () => import('./pages/actualizar/actualizar.module').then( m => m.ActualizarPageModule)
  },
  {
    path: 'pedido/:id',
    loadChildren: () => import('./pages/pedido/pedido.module').then( m => m.PedidoPageModule)
  },
  {
    path: 'registro',
    loadChildren: () => import('./pages/registro/registro.module').then( m => m.RegistroPageModule)
  },
  {
    path: 'docs',
    loadChildren: () => import('./pages/docs/docs.module').then( m => m.DocsPageModule)
  },
  {
    path: 'doc/:id',
    loadChildren: () => import('./pages/doc/doc.module').then( m => m.DocPageModule)
  },
  {
    path: 'soporte',
    loadChildren: () => import('./pages/soporte/soporte.module').then( m => m.SoportePageModule)
  },
  {
    path: 'chat/:id',
    loadChildren: () => import('./pages/chat/chat.module').then( m => m.ChatPageModule)
  },
  {
    path: 'chats',
    loadChildren: () => import('./pages/chats/chats.module').then( m => m.ChatsPageModule)
  },
  {
    path: 'pedidos',
    loadChildren: () => import('./pages/pedidos/pedidos.module').then( m => m.PedidosPageModule)
  },
  {
    path: 'notif',
    loadChildren: () => import('./pages/notif/notif.module').then( m => m.NotifPageModule)
  },
  {
    path: 'homeicon',
    loadChildren: () => import('./pages/homeicon/homeicon.module').then( m => m.HomeiconPageModule)
  },
  {
    path: 'encuestas',
    loadChildren: () => import('./pages/encuestas/encuestas.module').then( m => m.EncuestasPageModule)
  },


  
    {
    path: 'form',
    loadChildren: () => import('./modals/form/form.module').then( m => m.FormPageModule)
  },
  /*
  {
    path: 'tform',
    loadChildren: () => import('./directivas/tform/tform.module').then( m => m.TformPageModule)
  },
  {
    path: 'tristate',
    loadChildren: () => import('./directivas/tristate/tristate.module').then( m => m.TristatePageModule)
  },
  {
    path: 'viewform',
    loadChildren: () => import('./directivas/viewform/viewform.module').then( m => m.ViewformPageModule)
  },*/
  
  
  
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
