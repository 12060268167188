import { UtilService } from './util.service';
import { Producto } from './../modelos/producto';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { CacheService } from 'ionic-cache';
import { Pedido } from "../modelos/pedido";

@Injectable({
  providedIn: 'root',
})
export class PedidosService extends BaseService {
  conn: boolean;
  pedidos: Pedido[];

  productos: Producto[]; //Productos del Pedido actual
  categ: any[];  //LIsta de categorías

  constructor(protected http: HttpClient, protected cache: CacheService, public utilS: UtilService) {
    super(cache, http, utilS);
    this.cachekey = 'pedidos';
  }

  init(): Promise<any> {
    this.pedidos = [];
    this.loaded = false;
    return this.clear();
  }
  GetAll(): Observable<any> {
    return this.get('pedidos');
  }

  async loadAll(force: boolean = false) {
    if (force || !this.loaded) {
      this.utilS.loading = true;
      this.GetAll().subscribe((res) => {
        this.pedidos = res;
        this.utilS.loading = false;
      })
    }
  }
  GetPedidoById(id): Observable<any> {
    return this.getdirect('pedidos/' + id);
  }
  GetPedusu(): Observable<any> {
    return this.getdirect('pedusuarios');
  }

  public findproducto(id): Producto {
    /*for (var i = 0; i < this.pedido.productos.length; i++) {
        if (this.pedido.productos[i].id == id)
            return this.pedido.productos[i];
    }
    return null;*/
    //No va en android 4
    return this.productos.find(el => { return el.id == id });
  }
  public findidx(id): number {
    return this.productos.findIndex(el => { return el.id == id });
  }
  public findcategoria(id): any {
    return this.categ.find(el => { return el.id == id });
  }

  Update(pedido, data) {
    console.log("update", pedido, data);
    if (pedido.misdatos) {
      //Ya existe
      if (data.length) {
        return this.put('pedusuarios/' + pedido.misdatos.id, {
          data: data,
        });
      } else
        return this.delete('pedusuarios/' + pedido.misdatos.id);
    } else if (data.length)
      return this.post('pedusuarios', {
        pedidos_id: pedido.id,
        data: data,
      });
  }
}
