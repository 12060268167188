import { Doc } from './../modelos/doc';
import { UtilService } from './util.service';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { CacheService } from 'ionic-cache';

@Injectable({
  providedIn: 'root',
})

export class DocsService extends BaseService {
  conn: boolean;
  docs: Doc[];
  doc:Doc; //Doc actual con su contenido. Se utiliza en DocPage para no recargar cada vez

  //public dataSource = new BehaviorSubject<Doc[]>(null);
  //public data$ = this.dataSource.asObservable();

  constructor(protected http: HttpClient, protected cache: CacheService,public utilS:UtilService) {
    super(cache, http,utilS);
    this.cachekey = 'temas';
  }

  init():Promise<any>{
	this.loaded=false;
	this.docs=[];
	return this.clear();
 }

  async loadAll(force:boolean=false){
	if(force || !this.loaded)
	this.GetAll().subscribe((res)=>{
		//this.dataSource.next(res);
		this.docs=res;
	})
  }

  GetById(id,params:string='expand=media,tipos',ttl=-1): Observable<any> {
    return this.get('temas/' + id,params,ttl);
  }

  Create(datos): Observable<any> {
    console.log(datos);
    return this.post('temas', datos);
  }
  
  
  CreateMedia(datos): Observable<any> {
    return this.post('media',datos);
  }

  GetMedia(id,params:string='expand=object',ttl=-1): Observable<any> {
      return this.get('media/' + id,params,ttl);
  }

  UpdateMedia(id, datos): Observable<any> {
    this.clear();
    return this.put('media/' + id, datos);
  }
  CopiaMedia(id,data): Observable<any> {
	  console.log(id,data);
    //Con media/copy peta :(
    return this.getdirect('media/'+id, "temas_id="+data.temas_id+"&modo="+data.modo);
  }

}
