import { DbService } from './db.service';
/*
Entro en un chat:
  -Se leen minid=min(id) y maxid=max(id) de la BD
  -get modo L,id=maxid de la API y se meten en BD
  -Se asigna this.mensajes desde la BD  (los últimos 200 o 30 dias)
LLego al top:
  -Se cambia el filter de mensajes
Envío un mensaje:
  -Se mete en la BD
  -Se envía
  -Cuando se recibe, se actualiza en la BD el estado
Notificación mensaje
  -Se mete en la BD
  -Se añade a this.mensajes

*/
//import { JsonSQLite } from '@capacitor-community/sqlite';
import { Injectable } from '@angular/core';
import { Observable, of, throwError, Subject, Subscription } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { UtilService } from './util.service';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { CacheService } from 'ionic-cache';
import { Mensaje } from '../modelos/mensaje';
import { Chat } from '../modelos/chat';
import { OnesignalService } from './onesignal.service';

@Injectable({
  providedIn: 'root',
})
export class ChatsService extends BaseService {
  //public ttl:number=1;
  public numpendientes = 0;
  public chats: Chat[];
  public activo: Chat; //Chat activo
  public mensajes: Mensaje[]; //Mensajes chat activo
  public minid: number; //Min id de mensajes en la BD , del chat activo
  public maxid: number; //Max id  "   "

  //Suscripción a Osignal
  public mensajesSource = new BehaviorSubject<Mensaje[]>(null);
  public mensajes$ = this.mensajesSource.asObservable();

  //Evento que se emite al recibir mensaje
  public onReceivedSource = new Subject<void>();
  public onReceived$ = this.onReceivedSource.asObservable();

  constructor(
    protected http: HttpClient,
    public cache: CacheService,
    public utilS: UtilService,
    public onesignal: OnesignalService,
    //public db:DbaseService,
    public db:DbService
    //public storage:Storage
  ) {
    super(cache, http, utilS);
    this.cachekey = 'chats';
    console.log('construct chat.service');
  }
  //  mensajeSubject = new BehaviorSubject<any>(null);

  GetAll(): Observable<any> {
    return this.getdirect('chats', '');
  }

  async syncChats(all:boolean=false,activarid:string=null):Promise<any>{
    if(all)  await this.db.reset();
    this.GetAll().subscribe((res)=>{
      this.chats=res;
      this.numpendientes=0;
      if(activarid>'')
        this.SetActivo(activarid);
      this.chats.map((chat)=>{
        /*this.db.exec(`insert or ignore into chats (id,descri,tipo,minid)
           values (${chat.id},"${chat.descri}","${chat.tipo}",${chat.minid})`);*/
        this.numpendientes+=parseInt(chat.numpendientes);
        this.db.exec(`insert or ignore into chats (id,descri,tipo,minid) values (?,?,?,?)`,
          [chat.id,chat.descri,chat.tipo,chat.minid]);

      });
      /* ??
      this.db.query('select * from chats').then(v=>{

        //console.log(v);
      });*/
    })
  }
  //Sincroniza mensajes de un chat
  async syncMensajes():Promise<any>{
    await this.db.reset();
    console.log('Sincronizando mensajes ');
    let m=await this.db.query('select * from chats');
    let id=0;
    this.mensajes=[];
    await this.GetMessages('L',id).subscribe((res)=>{
      this.addMensajes(res);
    })
  }

  //Asigna chat activo y devuelve mensajes (de caché, si están)
  async SetActivo(id: string) {
    let idn = parseInt(id);
    if (this.activo && this.activo.id == idn) return;
    this.mensajes = [];
    if(this.chats)
      for (let i = 0; i < this.chats.length; i++) {
        if (this.chats[i].id == idn) this.activo = this.chats[i];
      }
    this.minid=null;
    this.maxid=null;

    if(this.db.native){
      let ret= await this.db.query('select min(id) minid,max(id) maxid from mensajes where chatid='+this.activo.id);
      if(ret.rows.length) {
        this.minid=ret.rows.item(0).minid;
        this.maxid=ret.rows.item(0).maxid;
      }
    }
    //console.log('mensajes en BD desde ',this.minid,this.maxid);
    /*this.get('chats/' + id, 'expand=model,mensajes' ,0).subscribe((res)=>{
		this.mensajesSource.next(res);
	});	*/
  }


  //Lee mensajes de la BD
  public dbGetMessages(modo:string='L',limit:number=100):Promise<any>{
    var sql:string;
    var where:string;
    if(modo=='L') {
      if(this.mensajes.length)
        where=" and id<"+this.mensajes[0].id;
       else
        where="";
      sql="SELECT * FROM (SELECT * FROM mensajes where chatid=? "+where+" ORDER BY id DESC LIMIT "+limit+")  ORDER BY id";
    } else
      sql="select * from mensajes where chatid=?";

    return this.db.query(sql,[this.activo.id]);
    /*.then(res=>{
      this.mensajes=res.values;
    })*/
}
  //De la api
  GetMessages(modo:string='L',id:number=0): Observable<any> {
    return this.getdirect('mensajes',this.getclave(modo,id));
  }

  //Lee de la API los no leidos, directos
  GetLastMessages(): Observable<any> {
    let id = this.maxid? this.maxid+1 : 0;
    return this.getdirect('mensajes',this.getclave('L',id));
  }

  public listenOsignal() {
    if (this.osignalX) return;
    console.log('chat service listen OS ');
    this.osignalX = this.onesignal.mensaje$.subscribe((res) => {
      if (res) {
        //console.log('mensaje$ de OS ', res);
        if (res.chats_id != this.activo?.id) {
          this.addPendiente(res.chats_id, 1);
        } else {
          let img=res.mensaje?.u.img;
          //if(img) img=img.replace('.png','');
          //Viene con formato antiguo. Cuando se cierre la otra api, se podría estandarizar
          let m:Mensaje={id:res.mensaje.id, chatid:res.mensaje.chats_id, uid:res.mensaje.usuarios_id, t:res.mensaje.texto,
               f:res.mensaje.f.substring(0,10), h: res.mensaje.f.substring(11,16),
               nom:res.mensaje?.u.nom, img:img,
              rel:res.mensaje?.u.id,stat:'+'};
          this.addMensajes([m]);
          this.onReceivedSource.next(); //Avisa de recepción
        }
      }
    });
  }

  GetById(id: string): Observable<any> {
    //this.activo=parseInt(id);
    return this.get('chats/' + id, 'expand=model,mensajes', -1); //Coge solo de caché. El resto se piden con lastmessages
  }

  public findMessage(id: number): Mensaje {
    let i = 0;
    while (i < this.mensajes.length) {
      if (this.mensajes[i].id == id) return this.mensajes[i];
      i++;
    }
    return null;
  }

  //Actualiza fecha del chat activo
  async UpdateFecha() {
    this.numpendientes-= this.activo.numpendientes;
    //console.log('upd fecha');
    this.put('chats/' + this.activo.id, {}).subscribe((res) => {
      //console.log('upd fecha', res);
      this.setPendiente(this.activo.id, 0);
    });
  }
  Reportar(mensaje) {
    this.put('mensajes/' + mensaje.id, {report:1}).subscribe((res) => {
      console.log('reportado', res);
    });
  }
  
   //Lee num de mensajes pendientes total
  GetPendientes() {
    this.numpendientes=0;
    this.get('stat', 'expand=chatspend', 1).subscribe(
      (res) => {
        //console.log('Chat getpendientes', res);
        this.numpendientes = parseInt(res.chatspend);
      },
      (err) => {}
    );
    /*	this.numpendientes = 0;
    	for (var i = 0; i < res.data.length; i++) {
    		this.numpendientes += parseInt(res.data[i].numpendientes);*/
  }

  //Añade mensaje de usuario y lo envía a la api
  PostMensaje(m: any): Observable<any> {
    m.stat = '-' + this.mensajes.length.toString();
    m.chatid=this.activo.id;
    this.mensajes.push(m);
    return this.post('mensajes', m);
  }

  //Añade mensaje a la BD, y si append=true, a this.mensajes
  public async addMensajes(men:Mensaje[],append:boolean=true){

    let set=[];
    let sql="insert or ignore into mensajes (id,chatid,uid,f,h,nom,t,stat,img,rel) values (?,?,?,?,?,?,?,?,?,?)";
    for (let i=0;i<men.length;i++) {
      if(append)
        this.mensajes.push(men[i]);
      let m=men[i];

      //this.db.exec(sql,[m?.id, m.chatid, m?.uid , m?.f, m?.h, m?.nom, m?.t, m?.stat, m?.img, m?.rel]);
      set.push({statement:sql,values:[m?.id, m.chatid, m?.uid , m?.f, m?.h, m?.nom, m?.t, m?.stat, m?.img, m?.rel]});
      if(set.length==30 || i==men.length-1) {
        await this.db.execSet(set).then(r=>{
          console.log('exec',r);
        });
        set=[];
      }
      if(m.id>this.maxid || this.maxid==null) this.maxid=m.id;
      if(m.id<this.minid || this.minid==null) this.minid=m.id ;
    }
  }
  // Modifica el contador de pendientes  de un chat . Si modo es I, incremente. Si es S, asigna
  setPendiente(chatid: number, n: number ) {
    if (this.chats)
      this.chats.map((chat) => {
        if (chat.id == chatid) {
          this.numpendientes+= +n-chat.numpendientes;
          chat.numpendientes = +n;
        }
      });
  }


  // Modifica el contador de pendientes  de un chat . Si modo es I, incremente. Si es S, asigna
  addPendiente(chatid: number, n: number = 1) {
    if (this.activo?.id != chatid) {
      if (this.chats)
        this.chats.map((chat) => {
          //Suma pendientes
          if (chat.id == chatid) {
              chat.numpendientes= +chat.numpendientes+n;
              this.numpendientes=+this.numpendientes+n;
          }
        });
    }
  }
  private getclave(modo:string='L',id:number=0,limit:number=100) {
    return 'chatid=' + this.activo.id+'&modo='+modo+"&id="+id+"&limit="+limit;
  }

}
