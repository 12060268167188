import { Component, OnInit, ElementRef } from '@angular/core';
import { Platform } from '@ionic/angular';
import { DatePipe } from '@angular/common';
import { ModalController } from '@ionic/angular';
import { Md5Service } from '../../services/md5.service';
//import { BarcodeScanner } from '@awesome-cordova-plugins/barcode-scanner/ngx';
import { from } from 'rxjs';
import { EnvironService } from '../../services/environ.service';
import { BrowserModule } from '@angular/platform-browser';

@Component({
  selector: 'app-acceso',
  templateUrl: './acceso.page.html',
  styleUrls: ['./acceso.page.scss'],
})

export class AccesoPage implements OnInit {
  carnet: any;

  constructor(
    private datePipe: DatePipe,
    public element: ElementRef,
    private md5: Md5Service,
    private platform: Platform,
	public environ: EnvironService,
	public modalController: ModalController
   // private barcodeScanner: BarcodeScanner
  ){ }

  ngOnInit() {
      var date = new Date();
      this.carnet = this.datePipe.transform(date,"yyMMddHHmm")+ '.' + this.environ.user.id;
      this.carnet = this.carnet+'.'+this.md5.md5(this.carnet).substring(3, 6);
  }
  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      'dismissed': true
    });
  }
}
