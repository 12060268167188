import { Component, OnInit , Input } from '@angular/core';
import { StorageService } from '../../services/storage.service';
import { UserService } from '../../services/user.service';
import { ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { Cliente } from '../../modelos/cliente';
import { API} from '../../api';


@Component({
  selector: 'app-modal',
  templateUrl: './modal.page.html',
  styleUrls: ['./modal.page.scss'],
})
export class ModalPage implements OnInit {

  isDesktop: boolean;
  user: any=[];
  personalizada:string = API.personalizada;
  api: string = API.url;
  @Input() Foto: any;
  @Input() Salir: any;
  @Input() Condiciones : any ;

  //@Input() conf: string;
  cliente: Cliente;
  data: any = [];
  conf:string;

  constructor(
    public modalController: ModalController,
    private userS: UserService,
    private storage: StorageService,
    private router: Router,
    ) {
      this.storage.getObject('cliente').then((data: any) => {
        this.cliente = data;
      });
    }


  closeModal() {
     this.modalController.dismiss();

    }

  eligeFoto(tipo){
    this.modalController.dismiss(tipo);
  }

  volver(){
    this.modalController.dismiss('','');
  }

  salir(){

    if(this.personalizada)
      this.router.navigate(['login'])
    else
      this.router.navigate(['cliente'])

  }


  ngOnInit() {

  }

}
