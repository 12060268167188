import { Component, OnInit, ElementRef, ViewChild, Input } from '@angular/core';
import { ActivatedRoute, GuardsCheckStart } from '@angular/router';
import { Location } from '@angular/common';
import { EnvironService } from '../../services/environ.service';
import { UtilService } from '../../services/util.service';
import { ApuntesService } from 'src/app/services/apuntes.service';
import { ApunteG } from 'src/app/modelos/apunteG';
import { User } from 'src/app/modelos/user';
import { ModalController } from '@ionic/angular';
import { Apunte } from 'src/app/modelos/apunte';
import { Usuario_r } from 'src/app/modelos/usuario_r';

@Component({
  selector: 'app-apunte',
  templateUrl: './apunte.page.html',
  styleUrls: ['./apunte.page.scss'],
})
export class ApuntePage implements OnInit {
  @Input() user: Usuario_r;
  @Input() saldo: number;
  public apunte:ApunteG;
  public apuntes:Apunte[];
  public moneda:string;

  constructor(
    private route: ActivatedRoute,
    public location: Location,
    public utilS: UtilService,
    public environ: EnvironService,
    public element: ElementRef,
    public apuntesS: ApuntesService,
    public modalController: ModalController
  ) {}

  ngOnInit() {
    this.moneda=this.environ.cliente?.lang.moneda;
  }

  ionViewWillEnter() {
    ////this.environ.csstoolbar('toolbar_ap');
    document.getElementById('capunte').style.setProperty('--background', this.environ?.css.bgcolorP);

    this.apunte=new ApunteG;
    //this.apunte.fecha=new Date().toDateString();
    this.apunte.importe=-this.saldo;
    this.apunte.usuarios_id=this.user.id.toString(); 
    this.apunte.cuentas_id=this.environ.cliente.cuentas[0].id;
    console.log('apunte ', this.user, this.apunte);
    if(this.saldo===null) this.verapuntes();
  }

  verapuntes() {
    this.utilS.presentLoading();
    this.apuntesS.GetUser(this.user.id.toString()).subscribe(
      (res) => {
        this.apuntes = res.apuntes.apuntes;
        this.saldo = res.saldo;
        this.user.nom=res.nombre;
        this.user.ape=res.apellidos;
        console.log(this.apuntes);
        this.utilS.dismissLoading();
      },
      (err) => {
        this.utilS.dismissLoading();
        this.location.back();
      }
    );
  }

  closeModal(data: string = 'cancel') {
		this.modalController.dismiss(null, data);
	}

  guardar(){
   console.log('apunte ',this.apunte);
   if(this.apunte.importe<=0)
    this.utilS.showAlert('Importe incorrecto','Crear Apunte');
   else
    this.apuntesS
      .Create(this.apunte)
      .subscribe(
        (res) => {
       //   console.log('apunte actualizado');
          this.utilS.toast('Apunte creado');
          this.modalController.dismiss(res.importe,'ok');
        },
        (err) => {}
      );
  }
  trackByFn(index:number, item:any): number {
    return item.id;
  }
}
