import { Directive, ElementRef} from '@angular/core';

@Directive({
  selector: '[appColorHeader]'
})
export class AppColorHeaderDirective {

  constructor( private el: ElementRef) {
        el.nativeElement.style.backgroundColor = 'yellow';
  }

}

