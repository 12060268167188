import { Observable } from 'rxjs';
import { UtilService } from './util.service';
import { CacheService } from 'ionic-cache';
import { DomSanitizer } from '@angular/platform-browser';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';

import { Noticia } from '../modelos/noticia';
@Injectable({
  providedIn: 'root',
})
export class NoticiasService extends BaseService {
  public noticias: Noticia[];
  public enportada: number[]; //indice de las que hay en portada

  constructor(
    protected http: HttpClient,
    protected cache: CacheService,
    public utilS: UtilService,
    private sanitizer: DomSanitizer
  ) {
    super(cache, http, utilS);
    this.cachekey = 'noticias';
    this.noticias = [];
  }

  async loadAll(force: boolean = false) {
    console.log('loadAll noticias', force);
    if (force || !this.loaded)
      this.GetAll('', force ? 0 : -1).subscribe(
        (res) => {
          //console.log(res); 
          if (res['message']) {
            console.log('Error ', res['message']);
          } else {
            //console.log('noticias', res);
            this.enportada = [];
            this.noticias = res;
            this.utilS.loading = false;
            for (let index = 0; index < this.noticias.length; index++) {
              if (this.noticias[index].portada == '1' && !this.noticias[index].leida) this.enportada.push(index);
              for(let j=0;j<this.noticias[index].media.length;j++) //Todo . Utilizar el método del Modelo?
                this.noticias[index].media[j].source=this.utilS.sanitize(this.noticias[index].media[j].source,this.noticias[index].media[j].tipo);
            }
            this.loaded = true;
          }
        }
      );
  }
  addNoticia(noticia: Noticia) {
    console.log('añado noticia', noticia);
    let ok = false;
    this.noticias.forEach((n: Noticia, i, not) => {
      if (n.id == noticia.id) {
        if (noticia.portada && !not[i].portada) this.enportada.unshift(i);
        not[i] = noticia;
        ok = true;
      }
    });
    if (!ok) {
      this.noticias.unshift(noticia);
      //Reasigna enportada, que va por posición
      for (let i = 0; i < this.enportada.length - 1; i++) {
        this.enportada[i] = this.enportada[i] + 1;
      }
      if (noticia.portada == '1') this.enportada.unshift(0);
    }

    this.setcache('noticias', '', this.noticias);
  }

  leida(noticia: Noticia): Observable<any> {
    // Actualiza this.noticias, pero al ir ahome se llama a loadAll y carga de la caché y sigue apareciendo la noticia
    this.noticias.forEach((n: Noticia, i, not) => {
      if (n.id == noticia.id) {
        not[i].leida = 'x';
		let j=this.enportada.indexOf(i);
		if(j>-1) this.enportada.splice(j,1);
      }
    });
    this.setcache('noticias', '', this.noticias);

    //this.clear();
    return this.post('noticiasusu', { noticias_id: noticia.id });
  }
}
