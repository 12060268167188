import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { EnvironService } from './../../services/environ.service';
import { UserService } from '../../services/user.service';
import { ModalController, NavParams } from '@ionic/angular';
import { ApuntePage } from 'src/app/pages/apunte/apunte.page';

@Component({
  selector: 'app-usuario',
  templateUrl: './usuario.page.html',
  styleUrls: ['./usuario.page.scss'],
})

export class UsuarioPage implements OnInit {
  private id: string;
  user: any = {}

  constructor(
    private navParams: NavParams,
    private modalCtrl: ModalController,
    private userS: UserService,
    public environ: EnvironService,
    public modalController:ModalController
  ) { }

  ngOnInit() {
    this.id = this.navParams.get('id');
    console.log(this.id);
    this.userS.GetUsuarioById(this.id, "email,telefono").subscribe(
      (res: any) => {
        this.user = res;
        document.getElementById('contentusu').style.setProperty('--background', this.environ?.css.bgcolorP);
      },
      err => { }
    );

  }
  dismiss() {
    this.modalCtrl.dismiss();
  }
  telefono() {
    window.open("tel://" + this.user.telefono, '_system', 'location=yes'); return false;
  }


  async apuntesForm() {
    const modal = await this.modalController.create({
      component: ApuntePage,
      cssClass: 'sinformul-modal-css', //cssClass: 'depend-modal-css',
      componentProps: {
        user: this.user,
        saldo: null
      },
    });
    return await modal.present();
  }

  whatsapp() {
    //window.open("http://api.whatsapp.com/send?phone=+34"+ this.user.telefono ,'_system', 'location=false');return false;
    window.open("https://wa.me/34" + this.user.telefono, '_system', 'location=false'); return false;
  }

  email() {
    window.open("mailto:" + this.user.email, '_system', 'location=yes');
  }

}
