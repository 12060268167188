import { API } from './../api';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { Injectable, NgZone } from '@angular/core';
import { LoadingController, AlertController } from '@ionic/angular';
import { ToastController } from '@ionic/angular';

import { StorageService } from './storage.service';
import { BehaviorSubject } from 'rxjs';

import { Network } from '@capacitor/network';
import { ModalController } from '@ionic/angular';


// The Network API requires the following permission be added to your AndroidManifest.xml
//<uses-permission android:name="android.permission.ACCESS_NETWORK_STATE" />
@Injectable({
  providedIn: 'root',
})
export class UtilService {
  public loading: any;
  public conectado: string;
  
  public confcolors = { S: 'success', N: 'danger', P: 'medium' }; //Colores de confirmado
  public confcolorsCSS = { S: 'green', N: 'red', P: 'gray' }; //Colores de confirmado
  /*public conficons = {
    S: 'checkmark-circle',
    N: 'close-circle',
    P: 'alert-circle-outline',
  }; //iconos de confirmado*/
  public conficons = {
    S: 'thumbs-up',
    N: 'thumbs-down',
    P: 'alert-circle-outline',
  }; //iconos de confirmado
  //public meses = [    'Ene',    'Feb',    'Mar',    'Abr',    'May',    'Jun',    'Jul',    'Ago',    'Sep',    'Oct',    'Nov',    'Dic',  ];
  private _anyo: string;
  // public cliente: Cliente;
  public api: string = API.url;
  

  public modalController: ModalController;

  // Observable para pasar a home el tipo de usuario y poder actualizar el submenú y el menú de tabs
  /* private tipoUser = new BehaviorSubject<string>('U');
  public miUser = this.tipoUser.asObservable();

  public cambioCliente = new BehaviorSubject<Cliente>(null); //TODO this.cliente);
  public miCliente = this.cambioCliente.asObservable();*/

  public cambioConn = new BehaviorSubject<boolean>(true);
  public miConn = this.cambioConn.asObservable();

  handler = Network.addListener('networkStatusChange', (status) => {
    console.log('internet ', status);
    this.setconexion(status);
  });

  constructor(
    public loadingCtrl: LoadingController,
    public storage: StorageService,
    private alertCtrl: AlertController,
    private ToastCtrl: ToastController,
    public sanitizer: DomSanitizer,
    private router: Router,
    private zone: NgZone
  ) {}

  async HayInternet() {
    this.setconexion(await Network.getStatus());
    return this.conectado;
  }

  setconexion(status) {
    if (status.connected) this.conectado = status.connectionType;
    else this.conectado = '';
    console.log('internet ', this.conectado);
  }

  public changeConn(conn: boolean): void {
    this.cambioConn.next(conn);
  }

  async presentLoading(duration: number =  5 ) {
    this.loading=true; return;

    this.loading = await this.loadingCtrl.create({ duration: duration });
    await this.loading.present();
    /*.then((a) => {
        a.present().then(() => {
          if (!this.isLoading) {
            console.log('cierro');
            a.dismiss().then(() => {});
          }
        });
      });*/
  }

  async dismissLoading() {
    this.loading=false; return;
    if (!this.loading) return;
    console.log('dismiss');
    this.loading.dismiss(); //.then(() => {});
    this.loading = null;
  }

  // Muestra popup
  /*  public async Info(mensaje:string, title: any) {
    if (!window['cordova'])
		alert(mensaje);
    else {
      if (typeof title === 'undefined') title = '';

      const modal = await this.modalController.create({
        component: ModalInfoPage,
        cssClass: 'cliente-modal-css',
        componentProps: {
          Info: mensaje,
        },
      });
      return await modal.present();

    }
  }*/

  // Muestra popup
  public async showModal(component: any) {
    const modal = await this.modalController.create({
      component: component,
      cssClass: 'cliente-modal-css',
      componentProps: {},
    });
    return await modal.present();
  }
  async showErr(err: any) {
    this.toast('Ups! Ha habido un problema. ¿Funciona tu conexión?');
  }

  async showAlert(msg: string, title: string = '') {
    const alert = await this.alertCtrl.create({
      header: title,
      // subHeader: msg,
      buttons: [
        {
          text: msg,
          handler: () => {
            // E.g: Navigate to a specific screen
          },
        },
      ],
    });
    alert.present();
  }
  async showConfirm(msg: string, title: string) {
    let ret: boolean;
    const alert = await this.alertCtrl.create({
      cssClass: 'my-custom-class',
      header: title,
      message: msg,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('cancelar');
            ret = false;
            return false;
          },
        },
        {
          text: 'Sí',
          handler: () => {
            ret = true;
            return true;
          },
        },
      ],
    });

    await alert.present();

    return ret;
  }

  async toast(msg: string, xcolor: string = 'success') {
    if (msg === undefined) return;
    const toast = await this.ToastCtrl.create({
      message: msg,
      duration: 2000,
      position: 'top',
      color: xcolor,
      cssClass: 'toast',
      buttons: [
        {
          side: 'end',
          icon: xcolor == 'success' ? 'checkmark-outline' : 'thumbs-down',
          handler: () => {
            console.log('Favorite clicked');
          },
        },
      ],
    });
    toast.present();
  }

  public refresh(data: any) {
    data = 0;
  }

  //Lleva a un url forzando recarga
  gotoRefresh(ruta: any) {
    var current = this.router.url;
    console.log('goto refresh - desde', ruta, current);
    /*
	if(current==ruta[0]) {*/
    //Se hace así paa que si está en la página, ésta se recarge
    this.zone.run(() => {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate(ruta).then(() => {
          setTimeout(() => {
            //No refresca bien fondo y tabs
            this.router.navigate(ruta);
          }, 3000);
        });
      });
    });
    /*} else {
		this.router.navigate(ruta);
	}*/
  }

  public sanitize(url: string, type: string = 'I') {
    if (typeof url === 'string') {
      if (!url.includes('http')) url = this.api + url;
      if (type == 'V')
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
      else return url;
    } else return url;
  }
  /*public month(f: string) {
    
    return this.meses[parseInt(f.substring(5, 7)) - 1];
  }*/
  
  public anyo(f: string) {
    if (!this._anyo) this._anyo = new Date().getFullYear().toString();
    return f.substring(0, 4) != this._anyo ? f.substring(0, 4) : '';
  }
  public coldif(anchomedia:string){
    if(!anchomedia) return 9;
    return anchomedia=='12' ? '12' : 12-parseInt(anchomedia);
  }


}
