import { UtilService } from './util.service';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { CacheService } from 'ionic-cache';
import { Encuesta } from '../modelos/encuesta';

@Injectable({
  providedIn: 'root',
})
export class EncuestasService extends BaseService {
  conn: boolean;
  public encuestas: Encuesta[];

  constructor(
    protected http: HttpClient,
    protected cache: CacheService,
    public utilS: UtilService
  ) {
    super(cache, http, utilS);
    this.cachekey = 'encuestas';
  }

  init(): Promise<any> {
    this.encuestas = [];
    this.loaded = false;
    return this.clear();
  }

  GetAll(): Observable<any> {
    return this.get('encuestas', 'new=1');
  }
  async loadAll(force: boolean = false) {
    if (force || !this.loaded)
      this.GetAll().subscribe((res) => {
        this.encuestas = res;
        this.loaded=true;
        //console.log('encuestas'+ JSON.stringify(this.encuestas,null,'\t'));
      });
  }
  GetEncuestaById(id): Observable<any> {
    return this.getdirect(
      'encuestas/' + id,
      'expand=formulario,misdatos,detalle'
    );
  }

  Update(encuesta, data) {
    console.log('upd encuesta', encuesta.misdatos, data);
    if (encuesta.misdatos.id) {
      //Ya existe
      if (data) {
        return this.put('encusuarios/' + encuesta.misdatos.id, {
          data: data,
        });
      } else return this.delete('encusuarios' + data.id);
    } else if (data) {
      this.clear(); //Para que recargue
      return this.post('encusuarios', {
        encuestas_id: encuesta.id,
        data: data,
      });
    }
  }
}
