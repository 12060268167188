import { UtilService } from './util.service';
import { OnesignalService } from './onesignal.service';
//https://github.com/Nodonisko/ionic-cache

import { Injectable } from '@angular/core';
//import { EnvironService } from './environ.service';
//import { Cliente } from '../modelos/cliente';
//import { Currentuser } from '../modelos/currentuser';
//import { catchError, tap, map } from 'rxjs/operators';
import { Observable, of, Subscription, throwError } from 'rxjs';
import { CacheService } from 'ionic-cache';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { API } from '../api';

@Injectable({
  providedIn: 'root',
})
export class BaseService {
  public connected: boolean;
  public loaded: boolean = false; //Se ha llamado a loadAll().
  public cachekey: string = 'cache';
  public ttl: number = 3600;
  public osignalX: Subscription;
  public debug:boolean=true;

  constructor(
    protected cache: CacheService,
    protected http: HttpClient,
    
    public utilS: UtilService
  ) {
    this.cache.setOfflineInvalidate(false); //Si no hay conexión, se pueden ver datos
  }

  /*ttl=-1 indica que coja el valor de this.ttl.
   * Si es 0, lo hace con delayed: coge caché y luego hace la petición http
   */
  get(action: string, params: string = '', ttl: number = -1) {
    if (ttl == -1) ttl = this.ttl;
    if (action) {
      this.cache.itemExists(action + params).then((r) => {
        /*this.cache.getItem(action+params).then ((r)=>{
				console.log('en cache '+action+params,r);
			}).catch((er)=>{
				console.log(action+params,'caducado');
			})*/

        console.log(
          'APIGET ' + this.cachekey + ': ',
          action + '?' + params,
          (r ? ' Hay Caché ' : 'No caché.')+ 'ttl:' + ttl
        );
      });
    }
    //TODO?? this.clear();// clearAll();
    if (ttl == 0)
      return this.cache.loadFromDelayedObservable(
        action + params,
        this.http.get(API.url + '/' + action + '?' + params),
        this.cachekey,
        0,
        'all'
      );
    else
      return this.cache.loadFromObservable(
        action + params,
        this.http.get(API.url + '/' + action + '?' + params),
        this.cachekey,
        ttl
      );
    /*
	return this.http.get(API.url+'/clases?expand=permisos').pipe(
	  tap((res:Response)=>{},
	  catchError(this.handleError)
	));*/
  }

  setcache(action: string, params: string, value: any) {
    this.cache.itemExists(action + params).then((r) => {
      this.cache.saveItem(action + params, value);
    });
  }

  getdirect(action: string, params: string = ''): Observable<any> {
    if(this.debug) console.log('APIGETDIRECT : ', action, params);
    return this.http.get(API.url + '/' + action + '?' + params);
  }

  async getwait(action: string, params: string = '') {
    
    let promise = new Promise((resolve, reject) => {
      var data = '';
      this.http.get(API.url+ '/' + action + '?' + params).subscribe( res => {
            resolve(res);
        }) 
    });

    let result = await promise; // wait until the promise resolves
    return result;
  }


  getdelay(action: string, params: string = ''): Observable<any> {
    if(this.debug) console.log('APIGET ' + this.cachekey + ': ', action, params);
    let delay = 'all'; //'all' para devolver siempre cache y hacer peticion inmediatamente. none para hacer la petición cuando esté expirada la caché
    return this.cache.loadFromDelayedObservable(
      action + params,
      this.http.get(API.url + '/' + action + '/' + params),
      this.cachekey,
      this.ttl,
      delay
    );
  }

  GetAll(params: string = '', ttl: number = -1): Observable<any> {
    return this.get(this.cachekey, params, ttl);
  }

  GetById(id, params: string = '', ttl: number = -1): Observable<any> {
    return this.get(this.cachekey + '/' + id, params, ttl);
  }

  post(action: string, data: any): Observable<any> {
    if(this.debug) console.log('APIPOST ' + this.cachekey, action);
    return this.http.post(API.url + '/' + action, data);
  }
  put(action: string, data: any): Observable<any> {
    if(this.debug) console.log('APIPUT ' + this.cachekey, action);
    return this.http.put(API.url + '/' + action, data);
  }
  delete(action: string): Observable<any> {
    if(this.debug) console.log('APIDELETE ' + this.cachekey, action);
    return this.http.delete(API.url + '/' + action);
  }

  clear(): Promise<any> {
    if(this.debug) console.log('clear cache ' + this.cachekey);
    this.loaded = false;
    return this.cache.clearGroup(this.cachekey);
  }

  clearAll(): Promise<any> {
    this.loaded = false;
    return this.cache.clearAll();
  }

  protected handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      if(this.debug) console.log('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      if(this.debug)  console.log('handleError ', error);
    }
    //No se puede hacer aquí this.utilS.toast('Ups. Se ha producido un error. ¿funciona bien tu conexión?','danger');
    // return an observable with a user-facing error message
    return throwError(error);
  }
  /*
  init(){
	  this.connected = true;

	  let disconnectSubscription = this.network.onDisconnect().subscribe(() => {
		console.log('network was disconnected.');
		this.connected = false;
	  });

	  // watch network for a connection
	  let connectSubscription = this.network.onConnect().subscribe(() => {
		console.log('network connected!');
		this.connected = true;

		// We just got a connection but we need to wait briefly
		// before we determine the connection type. Might need to wait.
		// prior to doing any api requests as well.
		setTimeout(() => {
		  if (this.network.type === 'wifi') {
			console.log(this.TAG + 'wifi connection available');
		  }
		}, 3000);
	  });
	}*/
}
