import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { DatePipe } from '@angular/common';
//import { BarcodeScanner } from '@awesome-cordova-plugins/barcode-scanner/ngx';
import { PhotoViewer } from '@awesome-cordova-plugins/photo-viewer/ngx';
//import { NgxIonicImageViewerModule } from 'ngx-ionic-image-viewer';
import { FormsModule, ReactiveFormsModule, FormGroup } from '@angular/forms';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

//import { OneSignal } from '@awesome-cordova-plugins/onesignal/ngx';
import { LaunchNavigator } from '@awesome-cordova-plugins/launch-navigator/ngx';
import { Geolocation } from '@awesome-cordova-plugins/geolocation/ngx';
import { CacheModule } from 'ionic-cache';
//import { NativeAudio } from '@awesome-cordova-plugins/native-audio/ngx';
//import { SQLite } from '@awesome-cordova-plugins/sqlite/ngx';
//import { SQLitePorter } from '@awesome-cordova-plugins/sqlite-porter/ngx';
//import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
//import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';

//import { SidemenuComponent } from './components/sidemenu/sidemenu.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppColorHeaderDirective } from './directivas/color-header.directive';
import { AppShowHideTabsDirective } from './directivas/app-show-hide-tabs.directive';
import { TokenInterceptor } from './interceptor/token.interceptor';

// Modals & Pipes
import { ModalPageModule } from './pages/modal/modal.module';
import { PipesModule } from './pipes/pipes.module';
import { AccesoPageModule } from './modals/acceso/acceso.module';
import { UsuarioPageModule } from './modals/usuario/usuario.module';
import { TformPageModule } from './directivas/tform/tform.module';

// SERVICIOS
import { NoticiasService } from './services/noticias.service';
import { AuthService } from './services/auth.service';
import { EnvironService } from './services/environ.service';
import { UtilService } from './services/util.service';
import { StorageService } from './services/storage.service';
import { UserService } from './services/user.service';
import { DocsService } from './services/docs.service';
import { ClasesService } from './services/clases.service';
import { PedidosService } from 'src/app/services/pedidos.service';
import { EncuestasService } from 'src/app/services/encuestas.service';
import { ChatsService } from './services/chats.service';
import { OnesignalService } from './services/onesignal.service';
import { DbService } from './services/db.service';

import { SQLite } from '@awesome-cordova-plugins/sqlite/ngx';
import { SQLitePorter } from '@awesome-cordova-plugins/sqlite-porter/ngx';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { IonicStorageModule } from '@ionic/storage-angular';
import { VideoEditor } from '@awesome-cordova-plugins/video-editor/ngx';
import { Camera } from '@awesome-cordova-plugins/camera/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { FileTransfer } from '@awesome-cordova-plugins/file-transfer/ngx';
import { Insomnia } from '@awesome-cordova-plugins/insomnia/ngx';
//import { SpeechRecognition } from '@awesome-cordova-plugins/speech-recognition/ngx';
import { SplashScreen } from '@capacitor/splash-screen';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import { QRCodeModule } from 'angularx-qrcode';

//import { DragDropModule} from '@angular/cdk/drag-drop';
//import { ChatPageModule } from './pages/chat/chat.module';

//import { Drivers } from '@ionic/storage';

//import { UniqueDeviceID } from '@awesome-cordova-plugins/unique-device-id/ngx';
//import { Uid } from '@awesome-cordova-plugins/uid/ngx';

// Firebase
//No va porque en nativo hace falta capacitor-firebase-auth y es incompatible con firebase@package.6.4
/*FIRE import { environment } from '../environments/environment';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { FirebaseAuthService } from './services/firebase-auth.service';
*/
registerLocaleData(localeEs);
@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    AppComponent,
    AppShowHideTabsDirective,
    AppColorHeaderDirective,
  ],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      //rippleEffect: true,
      mode: 'md',
    }),
    QRCodeModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    ModalPageModule,
    AccesoPageModule,
    UsuarioPageModule,
    //ChatPageModule,//.forRoot(), Esto es sí se utiliza SQlite ?
    PipesModule,
    //NgxIonicImageViewerModule,
    CacheModule.forRoot({ keyPrefix: 'cmb' }),
    //NgxQRCodeModule,
    IonicStorageModule.forRoot(),
    //FIRE	AngularFireModule.initializeApp(environment.firebase),
    //FIRE	AngularFireAuthModule
  ], 
  providers: [
    //		{provide: LOCALE_ID, useValue: 'es-US' },
    AppVersion,
    DatePipe,
    PhotoViewer,
    //BarcodeScanner,
    LaunchNavigator,
    NoticiasService,
    Geolocation,
    //NativeAudio,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    //OneSignal,
    
    OnesignalService,
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy,
    },
    AuthService,
    ChatsService,
    ClasesService,
    DocsService,
    EncuestasService,
    EnvironService,
    NoticiasService,
    PedidosService,
    StorageService,
    UserService,
    UtilService,

    //        DbaseService,
    DbService,
    SQLite,
    SQLitePorter,
    //SpeechRecognition,
    //SQLiteService,  // es el community
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    SocialSharing,
    VideoEditor,
    Camera,
    File,
    FileTransfer,
    
    Insomnia
    //UniqueDeviceID,
    //Uid,
      //SplashScreen,
    //FIRE FirebaseAuthService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
