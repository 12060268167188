import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor() {
	console.log('new Preferences');
  }
  async setString(key: string, value: string) {
    await Preferences.set({ key, value });
  }

  async getString(key: string): Promise<any> {
    //return await Preferences.get({ key });
	let ret=await Preferences.get({ key });
	if(!ret) return null;
	//console.log('get Preferences string '+key,ret.value);
	return ret.value;
  }

  async setObject(key: string, value: any) {
	//console.log('set Preferences '+key,value);
    await Preferences.set({ key, value: JSON.stringify(value) });
  }

  async getObject(key: string): Promise<any> {
    const ret = await Preferences.get({ key });
	//console.log('get Preferences '+key,ret.value);
	if(ret.value=='null')  return null;//ret.value='{}';
    return JSON.parse(ret.value);
  }

  async removeItem(key: string) {
    await Preferences.remove({ key });
  }

  async clear() {
    await Preferences.clear();
  }
  async refresh(data:any){
	  console.log('refresh ',data);
  }
}
