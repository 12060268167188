import { Usuario } from './../modelos/usuario';
import { UtilService } from './util.service';
import { Injectable } from '@angular/core';
import { Observable} from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { CacheService } from 'ionic-cache';
import { Form } from './../modelos/form';
import { EnvironService } from './environ.service';
import OneSignal, { OneSignalPlugin } from 'onesignal-cordova-plugin';
@Injectable({
  providedIn: 'root',
})
export class UserService extends BaseService {
  public user: any; //Usuario activo (se carga en ajustes y user)

  public userform: any; //Formulario de ficha de usuario (registro y perfil)

  constructor(
    protected http: HttpClient,
    protected cache: CacheService,
    protected environ: EnvironService,
	  public utilS: UtilService
  ) {
    super(cache, http,utilS);
    this.cachekey = 'user';
  }

  GetUser() {
	//this.clear();
  console.log(this.environ.device);
    return this.getdirect('user/9999', 'expand=customperfil,formulario');
  }

  GetUserSimple() {
    return this.get('user/9999');
  }

  reload(){
	  this.GetUser().subscribe((res)=>{
		  this.environ.user.saldo=res.saldo;
	  });
  }


  //Actualiza los datos que se le pasan
  Updatex(id, data) {
    return this.put('usuarios/' + id, data);
  }
//Actualiza los datos que se le pasan
  DeleteUser() {
    return this.delete('usuarios/1' );
  }
  GetUsuarioById(id, expand = '') {
    if (expand === undefined) expand = '';
    else expand = 'expand=' + expand;
    return this.get('usuarios/' + id, expand);
  }

  GetTexto(codigo:string) {
    return this.getdirect('plantillas/texto/' + this.environ.cliente.id, 'c='+codigo);
  }

  loadUserForm(){
  	return this.getdirect('plantillas/' + this.environ.cliente.id,'c=usuarios');
  }

  SetPlayerId(token: string): Observable<any> {
    console.log('setPlayerId');
    if (this.environ.userRol != 'I')
      return this.put('usuarios/' + this.environ.user.id, {
        login:true,
        fcm_token: token,
		    device:this.environ.device
      });
    else
      return this.put('devices/' + this.environ.user.id, {
        playerid: token,
		    device:this.environ.device
      });
  }

  UnSetPlayerId(): Observable<any> {
	  console.log('unset player id');
    /* No! porque dejarán de recibir mensajes el resto de dispositivos!!
    if(this.environ.isapp())
      OneSignal.removeExternalUserId();*/
    

    if (this.environ?.user) {
      if (!this.environ.isInvitado())

        return this.put('usuarios/' + this.environ.user.id, {
          login: false,
          device:this.environ.device,
        });
    } else
		  return this.delete('devices/999?uuid=' + this.environ.device.uuid); //TODO*/
  }
	//sale de sesión
	public async logout(){
		this.UnSetPlayerId().subscribe(r=>{
			this.environ.unsetUsuario();
		});
	  }

}
