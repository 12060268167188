import { CacheService } from 'ionic-cache';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { Observable, of, throwError } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { ModalController } from '@ionic/angular';
import { DeviceInfo } from '@capacitor/device';
import { Device } from '@capacitor/device';

import { StorageService } from './storage.service';
import { Cliente } from '../modelos/cliente';
import { Currentuser } from '../modelos/currentuser';
import { Xdevice } from '../modelos/xdevice';
import { Modulo } from '../modelos/modulo';
import { API } from '../api';
import { AuthService } from 'src/app/services/auth.service';
import { UsuarioPage } from '../modals/usuario/usuario.page';

@Injectable({
  providedIn: 'root',
})
export class EnvironService {
  public api: string = API.url;
  public lastcliente: string;
  public cliente: Cliente;
  public css: any; //Config de colores
  public cliversion:string; //Versión de cliente de la API. Si no coincide con la del cliente, se fuerza recarga
  public user: Currentuser;
  public device: Xdevice;

  public params: any;
  public screen: any;
  public m: {}; /* {modulo=>info del modulo}*/
  public homepage=['home'];
  public inmodules: {
    tab: Modulo[];
    fab: Modulo[];
    menu: Modulo[];
    home: Modulo[];
    hicon:Modulo[];
  };
  public revision=false; //Se pone a true si el password acaba con !! y eso fuerza recarga de cliente al entrar (para revisiones apple)

  // Observable para pasar a home el tipo de usuario y poder actualizar el submenú y el menú de tabs
  private tipoUser = new BehaviorSubject<string>('U');
  public miUser = this.tipoUser.asObservable();
  public userRol: string;

  public cambioCliente = new BehaviorSubject<Cliente>(null); //TODO this.cliente);
  public miCliente = this.cambioCliente.asObservable();
  private toolbars = {}; //Lista de toolbars tuneadas, para no repetir

  /*TODO
  public clase: Clase;
  public cambioClases = new BehaviorSubject<Clase>(null); //TODO this.clase);
  public miClase = this.cambioClases.asObservable();*/

  constructor(
    public storage: StorageService,
    public modalController: ModalController,
    public authS: AuthService,
    public app: AppVersion,
    public cache: CacheService
  ) {
    console.log('new environ');
    this.inmodules = { tab: [], fab: [], menu: [], home: [],hicon:[] };
    this.device = {
      platform: '',
      uuid: '',
      osversion: '',
      appversion: '',
      model: '',
      manufacturer: '',
    };
  }

  async initDevice() {
    this.device.platform = Capacitor.getPlatform();
    if (!this.isapp()) {
      this.device.uuid = '#web';
      this.device.appversion = API.appversion;
      this.device.osversion = 'web';
      API.personalizada=null;
    } else {
      let info: DeviceInfo = await Device.getInfo();
      let id = await Device.getId();
      this.device.uuid = id.identifier;//.uuid;
      this.device.osversion = info.osVersion;
      this.device.model = info.model;
      this.device.manufacturer = info.manufacturer;
      this.device.platform = info.platform;
      this.device.appversion = API.appversion;
      console.log('device info' ,info);  
    }
    console.log('device', this.device);
  }

  public setCliente(cli: Cliente, store: boolean): void {
    console.log('environ Set cliente' ,cli.cliente);
    this.cliente = cli;
    this.homepage=(cli.config?.mainpage.indexOf('B')!=-1)?['homeicon']:['home'];
    console.log('homepage',this.homepage);
    // calcula m e inmodules
    this.m={};

    for (let i = 0; i < cli.modules.length; i++) {
      this.m[cli.modules[i].id] = cli.modules[i]; //Se hace así para que sea rápido el acceso por variable, no por método
    }
    this.inmodules = { tab: [], fab: [], menu: [], home: [], hicon:[] };
    Object.entries(cli.inmodules).forEach(([key, value]) => {
      value.forEach((mid) => {
        this.inmodules[key].push(this.m[mid]);
      }); 
    });

 /*   for (let i = 0; i < cli.modules.length; i++) {
      this.m[cli.modules[i].id] = cli.modules[i]; //Se hace así para que sea rápido el acceso por variable, no por método
      if (cli.modules[i].in) {
        cli.modules[i].in.split(',').forEach((zona)=>{
          this.inmodules[zona].push(cli.modules[i]);
        });
      }
    }*/

    if (!cli.config?.css)
  		this.clearCliente();
    else {
      this.css = cli.config.css;
      this.css['fontsize'] = 1;
      this.css['txstyle'] = { color: this.css?.txcolor };
      this.css['menustyle'] = { color: this.css?.txcolor };
      this.css['headstyle'] = { color: 'white', background: this.css.txcolor };
      if(this.inmodules.tab.length>=6)
        this.css['tabstyle']= {'max-width':'50px','font-size':'9px','background':this.css.bgcolor};
      else 
        this.css['tabstyle']= {'background':this.css.bgcolor};

      if(this.css?.bgimage.length>0)
        this.css['background']={'background':'linear-gradient(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.3))','background-image':  'url('+this.fileurl(this.css.bgimage)+')'};
      else 
        this.css['background']={'background':this.css.bgcolorP};
      
      this.setfont();
      //if (this.cliente.lang.moneda == '€') this.monedaicon = 'saldoEU'; else this.monedaicon = 'saldoDO';

      if (store) {
        this.storage.setObject('cliente',cli);
        this.storage.setString('lastcliente', cli.cliente);
      }
    }
    //TODO this.cambioCliente.next(cli);
  }

  setfont(family='',size=1) {
    this.css.fontsize = size;
    if(family=='') family='Roboto, "Helvetica Neue", sans-serif';
    
    document.documentElement.style.setProperty('--ion-font-family', family);
    document.documentElement.style.setProperty('--ion-font-size', this.css.fontsize+'rem');
    console.log('font - size',family,this.css.fontsize);
  }



  clearCliente(reload=false) {
    console.log('environ clear cliente');
    this.storage.removeItem('cliente');
    //this.storage.removeItem('lastcliente');
    //this.storage.removeItem('user');
  }

  //Carga de storage cliente. Si no está, lo asigna si es personalizado
  async loadCliente(forceload=false) {
    if(API.personalizada)
      this.lastcliente=API.personalizada;
    else
      this.lastcliente = await this.storage.getString('lastcliente');
    if(this.lastcliente && forceload){
      await this.getCliente(this.lastcliente);
      return;
    }
    console.log('lastcliente',this.lastcliente);
    this.cliente = await this.storage.getObject('cliente');
    
    if(this.cliente && (this.cliente.cliente!=this.lastcliente ||this.revision)) this.cliente=null;
    if (this.cliente)
      this.setCliente(this.cliente, false);
    else if (API.personalizada) {
      await this.getCliente(API.personalizada);
    }
  }
  personalizada(): boolean {
    return (API.personalizada > '') && this.isapp();
  }

  //Carga de storage el usuario
  async loadUser() {
    this.user = await this.storage.getObject('currentUser');
    if (this.user) this.setRol();
    console.log('storage get user ', this.user);
  }

  // Define el usuario activo
  setUsuario(usuario: any) {
    this.storage.setObject('currentUser', usuario);
    this.user = usuario;
    if(this.user.nombre==='Test'){
      this.inmodules.tab.forEach((value,index)=>{
        if(value.id=='chats') this.inmodules.tab.splice(index,1);
      });
    }
    this.setRol();
    console.log('Environ SetUsuario ', usuario);
  }

  unsetUsuario() {
    this.storage.removeItem('currentUser');
    this.user = null;
    this.userRol = null;
    console.log('Environ clearUsuario ');
  }

  
  //Carga cliente de la API y lo asigna como activo
  async getCliversion() {

    await this.authS.getClienteApi('comboi').subscribe((res) => {
      console.log('cliversion de api ',res);
      this.cliversion=res['version'];
    });
  }

  //Carga cliente de la API y lo asigna como activo
  async getCliente(cliente: string) {
    this.authS.getClienteApi(cliente).subscribe((res) => {
        if (!res['message']) this.setCliente(res, true);
    });
  }

  //Borra storage
  async clear() {
    console.log('env clear');
    this.storage.clear();
    this.storage.removeItem('lastcliente');
  }

  // Borra caché y Recarga todo
  public async reload() {
    console.log('empieza reload');
    await this.cache.clearAll().then(() => {
      console.log('Cleared cache');
    });

	  await this.getCliente(this.cliente.cliente);
    console.log('fin reload');
  }

  isapp() {
    return this.device.platform != 'web';
  }

  isProfe(): boolean {
    return 'P' in this.user?.roles || 'G' in this.user?.roles;
  }
  isAdmin(): boolean {
    return ('C' in this.user?.roles) || ('G' in this.user?.roles) ;
  }
  isInvitado(): boolean {
    return !this.user || 'I' in this.user?.roles;
  }
  has(modulo: string) {
    return !this.isInvitado() && this.cliente && modulo in this.m;
  }

  //?Hace falta?
  public setRol(rol: string = ''): void {
    if (!rol) rol = this.user?.roles.I ? 'I' : 'U'; //TODO
    this.userRol = rol;
    this.tipoUser.next(rol);
  }

  /*
  public changeClase(clase: Clase): void {
    this.cambioClases.next(clase);
  }
*/

  public getmodules(xin: string) {
    //TODO. No sería this.inmodules[xin]?
    if (!this.cliente) return [];
    console.log(this.cliente?.modules.filter((m) => m.in == xin));
    return this.cliente?.modules.filter((m) => m.in == xin);
  }
  // Muestra popup
  public async showUser(userid: string) {
    const modal = await this.modalController.create({
      component: UsuarioPage,
      cssClass: 'usuario-modal-css',
      componentProps: {
        id: userid,
      },
    });
    return await modal.present();
  }

  public imgurl(file: string) {
    if(file===null) return '';
    return this.api + this.cliente?.imgurl + file;
  }
  public fileurl(file: string) {
    if(file.indexOf('http')==0) return file;
    //console.log('fileurl', this.api + this.cliente?.filesurl + file);
    if(file.indexOf('datcli')==-1) 
      return this.api + this.cliente?.filesurl + file;
    else
      return this.api + file;
  }
  
  public logourl(tipo: string = 'M') {
    //M=menu, L=logo grande 
    if(!this.cliente) return '';
    return (
      this.api +
      this.cliente?.imgurl +
      this.cliente[tipo == 'M' ? 'imagemenupath' : 'imagepath'] //??''
    );
  }
  public activimage(id){
    let act=this.cliente.actividades.find(a=>a.id==id);
    if(!act || !act.image) return '';
    return this.imgurl(act.image);
  }
  public activlabel(id,prop:string){
    let act=this.cliente.actividades.find(a=>a.id==id);
    if(!act ) return id+' sin act';
    return act?.labels[prop];
  }
  public activconfpend(id){
    let act=this.cliente.actividades.find(a=>a.id==id);
    if(!act ) return 1;
    return act?.confpend;
  }
  
  //Devuelve ruta inia
  public getRouteini(): string[] {
    if (API.personalizada) return ['login'];
    else return ['cliente'];
  }

  public async csstoolbar(id: string) {
    //if(this.toolbars[id]) return;
    var d = document.getElementById(id);
    var s = '';
    /*if((s=d.style.getPropertyValue('--background'))!=this.css?.txcolor){
		console.log("--back",s);*/
    if (d)
      //setTimeout(()=>{
      d.style.setProperty('--background', this.css?.txcolor);
    //},100);
    //}
    this.toolbars[id] = true;
  }
}
