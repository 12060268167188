export const chatsSchema: string = `

CREATE TABLE IF NOT EXISTS chats (
  id INTEGER PRIMARY KEY NOT NULL,
  descri char(60),
  tipo char(1),
  minid integer
);
CREATE TABLE IF NOT EXISTS mensajes (
  id INTEGER PRIMARY KEY NOT NULL,
  chatid integer not null,
  uid INTEGER,
  f char(10),
  h char(5),
  t TEXT NOT NULL,
  stat char(1),
  nom char(40),
  img char(16),
  rel integer,
  FOREIGN KEY (chatid) REFERENCES chats (id) ON DELETE CASCADE ON UPDATE CASCADE
);
CREATE INDEX IF NOT EXISTS mensajesindex ON mensajes (chatid);
PRAGMA user_version = 4;


CREATE TABLE IF NOT EXISTS notif (
  id INTEGER PRIMARY KEY AUTOINCREMENT,
  fecha datetime DEFAULT CURRENT_TIMESTAMP,
  tipo char(1),
  oid int,
  title varchar(160),
  body text
);


`;
/* Insert some Users
const row: Array<Array<any>> = [
  ['Whiteley', 'Whiteley.com', 30.2],
  ['Jones', 'Jones.com', 44],
];
export const twoUsers: string = `
DELETE FROM users;
INSERT INTO users (name,email,age) VALUES ("${row[0][0]}","${row[0][1]}",${row[0][2]});
INSERT INTO users (name,email,age) VALUES ("${row[1][0]}","${row[1][1]}",${row[1][2]});


`;

`;*/
