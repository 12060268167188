import { Claseusu } from './../../modelos/claseusu';
import { Filtroclase } from './../../modelos/filtroclase';
import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'filterclaseusu'
})
export class FilterclasePipe implements PipeTransform {

  transform(users: Claseusu[], filtro: Filtroclase): Claseusu[] {
	console.log("filtro ",filtro);
    if (filtro.nombre=='' && filtro.rol=='' && filtro.conf=='') {
      return users;
    } else {
      return users.filter(item => {

  		  if(item.confirmado=='N') return false;
  		  if(filtro.nombre>'' &&
              (item.u.nom+item.u.ape).toLocaleLowerCase().includes(filtro.nombre.toLocaleLowerCase()))
  			return true;
  		  if(filtro.rol>'' && item.rolAlias==filtro.rol) return true;
  		  if(filtro.conf>'' && item.confirmado==filtro.conf) return true;
  		  return false;
      });
    }
  }

}
