// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.usuario-modal-css .modal-wrapper {
  --width: fit-content;
  --min-width: 300px;
  --height: fit-content;
  position: absolute;
  display: block;
}

.xxxusuario-modal-css {
  --width: fit-content;
  --min-width: 300px;
  --height: fit-content;
  --border-radius: 6px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
}`, "",{"version":3,"sources":["webpack://./src/app/modals/usuario/usuario.page.scss"],"names":[],"mappings":"AACC;EACA,oBAAA;EACG,kBAAA;EACA,qBAAA;EAEH,kBAAA;EACA,cAAA;AADD;;AAKA;EAEI,oBAAA;EACA,kBAAA;EACA,qBAAA;EACA,oBAAA;EACA,4CAAA;AAHJ","sourcesContent":[".usuario-modal-css {\r\n\t.modal-wrapper {\r\n\t--width: fit-content;\r\n    --min-width: 300px;\r\n    --height: fit-content;\r\n\r\n\tposition: absolute; \r\n\tdisplay: block;  \r\n }\r\n}\r\n\r\n.xxxusuario-modal-css {\r\n\r\n    --width: fit-content;\r\n    --min-width: 300px;\r\n    --height: fit-content;\r\n    --border-radius: 6px;\r\n    --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
