// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // Lo lógico es ponerlo aquí, pero está en API.ts 
  // url: "http://localhost/comboi/api3/web",

  //No se usa, creo:
  firebase : {
	apiKey: "AIzaSyDdvujy3nS1jBj0RzezxdNWaPwrR13iSCk",
	authDomain: "comboi-e0ac6.firebaseapp.com",
	databaseURL: "https://comboi-e0ac6.firebaseio.com",
	projectId: "comboi-e0ac6",
//	storageBucket: "comboi-e0ac6.appspot.com",
//	messagingSenderId: "816735367194",
//	appId: "1:816735367194:web:d6b45e2c14113bffd6e1a3"
  }
 /* firebase: {
    apiKey: "AIzaSyB_hifCjvJb7jf3hnZozfzlnJQ6N0iYuXI",
    authDomain: "ionic5-firebase-authentication.firebaseapp.com",
    databaseURL: "https://ionic5-firebase-authentication.firebaseio.com",
    projectId: "ionic5-firebase-authentication",
  }*/
};

// Your web app's Firebase configuration
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
//TODO import 'zone.js/dist/zone-error';  // Included with Angular CLI.
